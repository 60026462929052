import {Order, Task} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {getCurrentRole, getJournalArray, getLocationLookup} from "@co-frontend-libs/redux";
import {DialogContent} from "@material-ui/core";
import {getJournalsForTask} from "app-utils";
import React from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {FieldsNotes} from "./fields-card";
import {FieldUseWithField} from "./fields-notes-reordering";
import {Linkify} from "./linkify";

const messages = defineMessages({
  notesTitle: {
    defaultMessage: "Noter på opgaven",
    id: "task-instance.dialog-title.note",
  },
});

interface NotesDialogProps {
  dismissed: boolean;
  onRequestClose: () => void;
  order?: Order | undefined;
  task: Task;
}

export const NotesDialog = React.memo(function NotesDialog(
  props: NotesDialogProps,
): React.JSX.Element {
  const {dismissed, order, task} = props;

  const journalArray = useSelector(getJournalArray);
  const locationLookup = useSelector(getLocationLookup);

  const customerJournalNotes = getJournalsForTask(task, order, journalArray);

  let fieldUseNotes = false;
  let fieldWithNotesList: FieldUseWithField[] = [];
  if (task.fielduseSet) {
    fieldWithNotesList = task.fielduseSet
      .map((fieldUse) => {
        if (!fieldUseNotes && fieldUse.notes && fieldUse.notes !== "") {
          fieldUseNotes = true;
        }
        const field = locationLookup(fieldUse.relatedField);
        if (field) {
          return {
            ...fieldUse,
            field,
          };
        } else {
          return undefined;
        }
      })
      .filter(notUndefined);
  } else {
    fieldWithNotesList = [];
  }
  const {formatMessage} = useIntl();
  let open;
  if (dismissed) {
    open = false;
  } else {
    const taskHasNote = !!(task && task.notesFromManager);
    const orderHasNote = !!(order && order.notes);
    let fieldsHaveNotes = false;
    if (fieldWithNotesList.length) {
      const fieldExtraNotes = new Map<string, string>();
      const orderFieldUseList = order && order.orderfielduseSet;
      if (orderFieldUseList && orderFieldUseList.length) {
        orderFieldUseList.forEach((fieldUse) => {
          const {notes} = fieldUse;
          if (notes) {
            const fieldURL = fieldUse.relatedField;
            fieldExtraNotes.set(fieldURL, notes);
          }
        });
      }
      fieldsHaveNotes = fieldWithNotesList.some((f) => f.notes || fieldExtraNotes.get(f.field.url));
    }

    open = taskHasNote || orderHasNote || fieldsHaveNotes || !!customerJournalNotes.length;
  }
  let fieldNotesBlock: React.JSX.Element | undefined;
  if (fieldWithNotesList.length) {
    const fieldExtraNotes = new Map<string, string>();
    const orderFieldUseList = order && order.orderfielduseSet;
    if (orderFieldUseList && !!orderFieldUseList.length) {
      orderFieldUseList.forEach((fieldUse) => {
        const {notes} = fieldUse;
        if (notes) {
          const fieldURL = fieldUse.relatedField;
          fieldExtraNotes.set(fieldURL, notes);
        }
      });
    }
    if (fieldWithNotesList.some((f) => f.notes || fieldExtraNotes.get(f.field.url))) {
      fieldNotesBlock = (
        <div>
          <br />
          <FormattedMessage
            defaultMessage="Marknoter fra administrationen:"
            id="notes-dialog.label.field-notes-from-admin"
          />
          <FieldsNotes extraNotes={fieldExtraNotes} fieldWithNotesList={fieldWithNotesList} />
        </div>
      );
    }
  }

  let customerJournalNotesblock;
  if (customerJournalNotes.length) {
    customerJournalNotesblock = (
      <div>
        <br />
        <FormattedMessage defaultMessage="Journal noter:" id="notes-dialog.label.journal-notes" />
        <ul>
          {customerJournalNotes.map((journalNote) => (
            <li key={journalNote.url}>{journalNote.entry}</li>
          ))}
        </ul>
      </div>
    );
  }
  const currentUserRole = useSelector(getCurrentRole);
  const currentUserIsManager = currentUserRole && currentUserRole.manager;
  return (
    <ResponsiveDialog
      cancelLabel={<FormattedMessage defaultMessage="Luk" id="notes-dialog.close" />}
      okLabel={<FormattedMessage defaultMessage="Luk" id="notes-dialog.close" />}
      onCancel={currentUserIsManager ? props.onRequestClose : undefined}
      onOk={!currentUserIsManager ? props.onRequestClose : undefined}
      open={open}
      title={formatMessage(messages.notesTitle)}
    >
      <DialogContent>
        <div style={{fontSize: 24, whiteSpace: "pre-wrap"}}>
          <Linkify>
            <div>{order && order.notes}</div>
            <div>{task && task.notesFromManager}</div>
            {fieldNotesBlock}
            {customerJournalNotesblock}
          </Linkify>
        </div>
      </DialogContent>
    </ResponsiveDialog>
  );
});
