import {ReadonlyConfig, schema} from "@co-common-libs/config";
import {Location, Role} from "@co-common-libs/resources";
import {
  ActiveEconomySystemIntegrationKind,
  EconomySystemIntegrationKindPart,
  getEconomySystemIntegrationKind,
} from "../get-economy-system-integration-kind";
import {liveSyncResource} from "../live-sync-resource";

type RoleIdentifier = (typeof schema.definitions.roles.enum)[number];

function getRoleIdentifiers(
  role: Pick<
    Role,
    | "jobber"
    | "machineOperator"
    | "manager"
    | "mechanic"
    | "projectManager"
    | "seniorMachineOperator"
  > | null,
): Set<RoleIdentifier> {
  const identifiers = new Set<RoleIdentifier>();
  if (role) {
    // The project manager role was added using a nasty hack. The role was added
    // due to our customers wanted their project managers to be able to manage
    // employee tasks, etc., but they should *not* have access to invoicing
    // (e.g. the e-conomic sync page). Thus the role was added, but with
    // implicit rule that if you have the project manager role then you must
    // also have the manager role... and afterward adding the a guard on the
    // e-conomic sync page stating "you can see the page if you are manager and
    // but not if you are project manager". Apparently the feature was so urgent
    // that saving time adding code for "if the user is manager or project
    // manager" everywhere that managers at that point only has access, except for the
    // e-conomic sync page, was prioritised.
    // TODO: We need to fix this insane legacy logic everywhere it is used and
    //       write a migration that sets `manager = false` for project managers.
    if (role.manager && !role.projectManager) {
      identifiers.add("manager");
    }
    if (role.manager && role.projectManager) {
      identifiers.add("project_manager");
    }
    if (role.seniorMachineOperator) {
      identifiers.add("senior_machine_operator");
    }
    if (role.machineOperator) {
      identifiers.add("machine_operator");
    }
    if (role.mechanic) {
      identifiers.add("mechanic");
    }
    if (role.jobber) {
      identifiers.add("jobber");
    }
  }
  return identifiers;
}

export type ExtendedLocationSettingsPart = EconomySystemIntegrationKindPart &
  Pick<
    ReadonlyConfig,
    | "allowMachineOperatorToEditLocationLocationType"
    | "economicEnableWorkplaceImport"
    | "economicInvoicePerWorkplace"
    | "economicUploadWorkPlaces"
    | "rolesMayCreateLocations"
    | "rolesMayEditLocations"
    | "showCustomerLocationNameOnInfoBlock"
  >;

function workplaceChangeAllowedBySync(
  config: ExtendedLocationSettingsPart,
  activeEconomySystemKind: ActiveEconomySystemIntegrationKind,
): boolean {
  const {
    brugerdataSync,
    c5Sync,
    economicEnableWorkplaceImport,
    economicInvoicePerWorkplace,
    economicSync,
    economicUploadWorkPlaces,
    navSync,
    navSyncProfile,
    unicontaSync,
  } = config;
  if (unicontaSync || navSync || brugerdataSync || navSyncProfile === "bejstrupm") {
    return true;
  } else if (c5Sync) {
    return false;
  } else if (economicSync) {
    if (economicUploadWorkPlaces) {
      return true;
    } else {
      return !economicEnableWorkplaceImport && !economicInvoicePerWorkplace;
    }
  } else {
    console.assert(activeEconomySystemKind === ActiveEconomySystemIntegrationKind.NONE);
    return true;
  }
}

export interface ExtendedLocationSettings {
  canChangeWorkplace: boolean;
  canCreateLocation: boolean;
  canCreateWorkplace: boolean;
  canEditField: (
    location: Pick<Location, "geojson" | "remoteUrl">,
    field: keyof Location,
  ) => boolean;
  canMergeLocations: boolean;
  canOpenEditLocation: boolean;
  canOpenEditWorkplace: boolean;
  canSeeLocationCustomerName: boolean;
}

export function extendedLocationSettings(
  config: ExtendedLocationSettingsPart,
  role: Pick<
    Role,
    | "jobber"
    | "machineOperator"
    | "manager"
    | "mechanic"
    | "projectManager"
    | "seniorMachineOperator"
  > | null,
): ExtendedLocationSettings {
  const {
    allowMachineOperatorToEditLocationLocationType,
    rolesMayCreateLocations,
    rolesMayEditLocations,
    showCustomerLocationNameOnInfoBlock,
  } = config;
  const economySystemIntegrationKind = getEconomySystemIntegrationKind(config);

  const roleIdentifiers = getRoleIdentifiers(role);

  const roleCanCreateLocation = rolesMayCreateLocations.some((identifier) =>
    roleIdentifiers.has(identifier),
  );

  const roleCanEditLocation = rolesMayEditLocations.some((identifier) =>
    roleIdentifiers.has(identifier),
  );

  const canChangeWorkplace = workplaceChangeAllowedBySync(config, economySystemIntegrationKind);

  const integrationAllowsManage =
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.NONE ||
    economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.LEGACY ||
    (economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.LIVE_SYNC &&
      liveSyncResource(config, "location"));

  return {
    canChangeWorkplace,
    canCreateLocation: roleCanCreateLocation,
    canCreateWorkplace: roleCanCreateLocation && canChangeWorkplace,
    canEditField: (location: Pick<Location, "geojson" | "remoteUrl">, field: keyof Location) => {
      if (field === "locationType") {
        return (
          !location?.geojson &&
          (roleCanEditLocation || allowMachineOperatorToEditLocationLocationType)
        );
      } else {
        return (
          roleCanEditLocation &&
          (!location.remoteUrl ||
            (economySystemIntegrationKind === ActiveEconomySystemIntegrationKind.LIVE_SYNC &&
              liveSyncResource(config, "location")))
        );
      }
    },
    // TODO(mr): if we refactor to use mass action, we can allow merge of CO-only locations while
    //           preventing merge of externally synced locations
    canMergeLocations: roleCanEditLocation && integrationAllowsManage,
    canOpenEditLocation: roleCanEditLocation,
    canOpenEditWorkplace: roleCanEditLocation && canChangeWorkplace,
    canSeeLocationCustomerName:
      showCustomerLocationNameOnInfoBlock || roleIdentifiers.has("manager"),
  };
}
