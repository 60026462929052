import {Config} from "@co-common-libs/config";
import {
  actions,
  AppState,
  getCustomerSettings,
  makePathParameterGetter,
  makeQueryParameterGetter,
} from "@co-frontend-libs/redux";
import {PartialNavigationKind} from "@co-frontend-libs/routing-sync-history";
import {Tab, Tabs} from "@material-ui/core";
import {PageLayout} from "app-components";
import {instanceURL} from "frontend-global-config";
import React, {useCallback, useMemo} from "react";
import {defineMessages, useIntl} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import ConnectedLoadOrderEntry from "./order-entry-tab";
import {OrderLogAppendices} from "./order-log-appendices";
import {OrderPhotoAppendices} from "./order-photo-appendices";

const messages = defineMessages({
  appendices: {
    defaultMessage: "Bilag",
  },
  details: {
    defaultMessage: "Detaljer",
  },
  logs: {
    defaultMessage: "Logs",
  },
  orderTitle: {
    defaultMessage: "Ordre",
  },
  photos: {
    defaultMessage: "Fotos",
  },
});

interface OrderEntryPageStateProps {
  customerSettings: Config;
  id: string;
  tab: string;
}

interface OrderEntryPageDispatchProps {
  putQueryKey: (key: string, value: string, navigationKind?: PartialNavigationKind) => void;
}

type OrderEntryPageProps = OrderEntryPageDispatchProps & OrderEntryPageStateProps;

const OrderEntryPage = ({
  customerSettings,
  id,
  putQueryKey,
  tab,
}: OrderEntryPageProps): React.JSX.Element => {
  const {formatMessage} = useIntl();
  const orderUrl = useMemo(() => instanceURL("order", id), [id]);

  let content: React.ReactNode | undefined;
  if (tab === "entry") {
    content = <ConnectedLoadOrderEntry />;
  } else if (tab === "photos") {
    content = <OrderPhotoAppendices orderUrl={orderUrl} />;
  } else if (tab === "logs") {
    content = <OrderLogAppendices orderUrl={orderUrl} />;
  }

  const handleTabChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: string): void => {
      putQueryKey("tab", value);
    },
    [putQueryKey],
  );

  return (
    <PageLayout
      tabs={
        customerSettings.enableAppendicesPage ? (
          <Tabs onChange={handleTabChange} value={tab}>
            <Tab label={formatMessage(messages.details)} value="entry" />
            <Tab label={formatMessage(messages.photos)} value="photos" />
            <Tab label={formatMessage(messages.logs)} value="logs" />
          </Tabs>
        ) : undefined
      }
      toolbar={formatMessage(messages.orderTitle)}
    >
      <div style={{margin: 20}}>{content}</div>
    </PageLayout>
  );
};

const ConnectedOrderEntryPage = connect<
  OrderEntryPageStateProps,
  OrderEntryPageDispatchProps,
  object,
  AppState
>(
  createStructuredSelector<AppState, OrderEntryPageStateProps>({
    customerSettings: getCustomerSettings,
    id: makePathParameterGetter("id"),
    tab: makeQueryParameterGetter("tab", "entry"),
  }),
  {
    putQueryKey: actions.putQueryKey,
  },
)(OrderEntryPage);

export {ConnectedOrderEntryPage as OrderEntryPage};
