import {
  Contact,
  Culture,
  Customer,
  Location,
  Order,
  PriceGroup,
  Task,
  urlToId,
} from "@co-common-libs/resources";
import {getMachineString, getWorkTypeString} from "@co-common-libs/resources-utils";
import {
  formatDate,
  formatDateShort,
  formatDateTime,
  formatDateTimeShort,
} from "@co-common-libs/utils";
import {FilePdfIcon, IconLinkButton} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentRole,
  getCustomerFileArray,
  getExtendedCustomerSettings,
  getJournalArray,
  getMachineLastUsedArray,
  getMachineLookup,
  getOrderFileArray,
  getProjectFileArray,
  getProjectLookup,
  getUserProfileArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {Button, Card, CardContent, Grid, IconButton} from "@material-ui/core";
import {
  AddOrderBDReference,
  CustomerFileListCard,
  Linkify,
  OrderFilesCard,
  ProjectFilesCard,
  RemoveOrderBDReference,
  TaskFields,
} from "app-components";
import {addDanishCountryPrefix, getJournalsForTask} from "app-utils";
import bowser from "bowser";
import {globalConfig} from "frontend-global-config";
import PencilIcon from "mdi-react/PencilIcon";
import PhoneIcon from "mdi-react/PhoneIcon";
import React, {useCallback, useMemo} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {CultureCard} from "./culture-card";
import {CustomerCard} from "./customer-card";

const GoToFilesButton = React.memo(function GoToFilesButton(): React.JSX.Element {
  const dispatch = useDispatch();
  const handleGoToFiles = useCallback(() => {
    dispatch(actions.go("/files-task"));
  }, [dispatch]);
  return (
    <div style={{margin: "1em"}}>
      <Button color="secondary" fullWidth onClick={handleGoToFiles} variant="contained">
        <FormattedMessage defaultMessage="Åben dokumenter" />
      </Button>
    </div>
  );
});

interface InfoTabContentProps {
  address?: string | undefined;
  completed: boolean;
  completedText?: React.JSX.Element | undefined;
  contact?: Contact | undefined;
  culture?: Culture | undefined;
  customer?: Customer | undefined;
  date?: string | undefined;
  hasActivity: boolean;
  onChangeCultureButton: () => void;
  onChangeCustomerButton: () => void;
  onChangeMachineOperatorClick: () => void;
  onGoToOrder: () => void;
  onRequestNotesDialogClose: () => void;
  order?: Order | undefined;
  otherMachineOperators?: React.JSX.Element[] | undefined;
  pickupLocation?: Location | undefined;
  priceGroup?: PriceGroup | undefined;
  responsibleInitials?: string | undefined;
  shareToken: string | null;
  task: Task;
  taskEffectiveTime: string;
  time?: string | undefined;
  userInitials?: string | undefined;
  userIsJobber: boolean;
  userIsOnlyMachineOperator: boolean;
  userIsOtherMachineOperator: boolean;
  userIsSeniorMachineOperator: boolean;
  validated: boolean;
  workplace?: Location | undefined;
  workType?: string;
}

export const InfoTabContent = React.memo(function InfoTabContent(
  props: InfoTabContentProps,
): React.JSX.Element {
  const intl = useIntl();
  const customerSettings = useSelector(getExtendedCustomerSettings);
  const journalArray = useSelector(getJournalArray);
  const machineLastUsedArray = useSelector(getMachineLastUsedArray);
  const machineLookup = useSelector(getMachineLookup);
  const projectLookup = useSelector(getProjectLookup);
  const userProfileArray = useSelector(getUserProfileArray);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const currentRole = useSelector(getCurrentRole);
  const orderFileArray = useSelector(getOrderFileArray);
  const projectFileArray = useSelector(getProjectFileArray);
  const customerFileArray = useSelector(getCustomerFileArray);

  const {
    address,
    completed,
    completedText,
    contact,
    culture,
    customer,
    date,
    hasActivity,
    onChangeCultureButton,
    onChangeCustomerButton,
    onChangeMachineOperatorClick,
    onGoToOrder,
    onRequestNotesDialogClose,
    order,
    otherMachineOperators,
    pickupLocation,
    priceGroup,
    responsibleInitials,
    shareToken,
    task,
    taskEffectiveTime,
    time,
    userInitials,
    userIsJobber,
    userIsOnlyMachineOperator,
    userIsOtherMachineOperator,
    userIsSeniorMachineOperator,
    validated,
    workplace,
    workType,
  } = props;

  const priceGroupBlock = useMemo((): React.JSX.Element | undefined => {
    if (priceGroup) {
      return <div>({priceGroup.name})</div>;
    } else {
      return undefined;
    }
  }, [priceGroup]);

  const fieldExtraNotes = new Map<string, string>();
  const orderFieldUseList = order && order.orderfielduseSet;
  if (orderFieldUseList && !!orderFieldUseList.length) {
    orderFieldUseList.forEach((fieldUse) => {
      const {notes} = fieldUse;
      if (notes) {
        const fieldURL = fieldUse.relatedField;
        fieldExtraNotes.set(fieldURL, notes);
      }
    });
  }

  const external = !!task.order;
  const pdfButton = useMemo((): React.JSX.Element | undefined => {
    if (customerSettings.taskInstancePDF) {
      const initials = responsibleInitials || "";
      const taskDate = date || "";
      const account = customer ? customer.c5_account : "";

      const pdfFilename = `opgave-${initials}-${taskDate}-${account}.pdf`;
      const pdfUrl = `${globalConfig.baseURL}/download/task/pdf/${urlToId(
        task.url,
      )}/${pdfFilename}?token=${shareToken}`;
      return (
        <Button
          href={pdfUrl}
          startIcon={<FilePdfIcon />}
          target={window.cordova && bowser.ios ? "_system" : "_blank"}
          variant="text"
        >
          <FormattedMessage defaultMessage="Download PDF" />
        </Button>
      );
    } else {
      return undefined;
    }
  }, [customer, customerSettings.taskInstancePDF, date, responsibleInitials, shareToken, task.url]);

  const customerCard = useMemo((): React.JSX.Element | undefined => {
    if (external && customerSettings.externalTaskCustomer) {
      return (
        <CustomerCard
          address={address}
          completed={completed}
          contact={contact}
          currentRole={currentRole}
          customer={customer}
          customerSettings={customerSettings}
          department={task.department}
          onChangeCustomerButton={onChangeCustomerButton}
          orderURL={task.order}
          pickupLocation={pickupLocation}
          taskURL={task.url}
          userIsOnlyMachineOperator={userIsOnlyMachineOperator}
          userIsOtherMachineOperator={userIsOtherMachineOperator}
          userIsSeniorMachineOperator={userIsSeniorMachineOperator}
          validated={validated}
          workplace={workplace}
        />
      );
    } else {
      return undefined;
    }
  }, [
    external,
    customerSettings,
    address,
    completed,
    contact,
    currentRole,
    customer,
    task.department,
    task.order,
    task.url,
    onChangeCustomerButton,
    pickupLocation,
    userIsOnlyMachineOperator,
    userIsOtherMachineOperator,
    userIsSeniorMachineOperator,
    validated,
    workplace,
  ]);

  const cultureCard = useMemo((): React.JSX.Element | undefined => {
    if (external && customerSettings.externalTaskCulture) {
      return (
        <CultureCard
          completed={completed}
          culture={culture}
          customerSettings={customerSettings}
          onChangeCultureButton={onChangeCultureButton}
          userIsOnlyMachineOperator={userIsOnlyMachineOperator}
          userIsOtherMachineOperator={userIsOtherMachineOperator}
          userIsSeniorMachineOperator={userIsSeniorMachineOperator}
          validated={validated}
        />
      );
    } else {
      return undefined;
    }
  }, [
    completed,
    culture,
    customerSettings,
    external,
    onChangeCultureButton,
    userIsOnlyMachineOperator,
    userIsOtherMachineOperator,
    userIsSeniorMachineOperator,
    validated,
  ]);

  const referenceNumber = useMemo((): React.JSX.Element | undefined => {
    if (customerSettings.showOrderReferenceNumberOnTaskInfoTab) {
      return (
        <Grid container>
          <Grid item>
            {customerSettings.orderReferenceNumberLabel ? (
              customerSettings.orderReferenceNumberLabel
            ) : (
              <FormattedMessage defaultMessage="Reference nummer" />
            )}
            <br />
            <h1>{order ? order.referenceNumber : ""}</h1>
          </Grid>
        </Grid>
      );
    } else {
      return undefined;
    }
  }, [
    customerSettings.orderReferenceNumberLabel,
    customerSettings.showOrderReferenceNumberOnTaskInfoTab,
    order,
  ]);

  const brugerdataReference: React.JSX.Element | undefined = useMemo(() => {
    if (customerSettings.showBrugerdataOrdernumber) {
      return (
        <Grid container>
          <Grid item>
            <FormattedMessage
              defaultMessage="Brugerdata-ordre: {number}"
              values={{number: order?.remoteUrl}}
            />
            {userIsOnlyMachineOperator ? null : <RemoveOrderBDReference order={order} />}
            {customerSettings.brugerdataCreateOrderButton ? (
              <AddOrderBDReference order={order} />
            ) : null}
          </Grid>
        </Grid>
      );
    } else {
      return undefined;
    }
  }, [
    customerSettings.brugerdataCreateOrderButton,
    customerSettings.showBrugerdataOrdernumber,
    order,
    userIsOnlyMachineOperator,
  ]);

  const lastMachineUseBlock = useMemo(() => {
    if (customerSettings.showMachineLastUse) {
      const machineuseSet = task.machineuseSet || [];
      const rows = machineuseSet.map((machineUse) => {
        const machineLastUsed = machineLastUsedArray.find(
          (lastUsed) => lastUsed.machine === machineUse.machine,
        );
        if (!machineLastUsed) {
          return null;
        }
        const machine = machineLookup(machineUse.machine);
        if (!machine) {
          return null;
        }
        const userProfile = userProfileArray.find(
          (profile) => profile.user === machineLastUsed.user,
        );
        const initials = userProfile ? userProfile.alias : "";
        const phone = userProfile ? userProfile.cellphone || userProfile.phone : null;
        const lastUsedWorkType = machineLastUsed.workType
          ? workTypeLookup(machineLastUsed.workType)
          : undefined;
        let machineString;
        let dateString;
        if (bowser.mobile) {
          machineString = machine.c5_machine;
          dateString = formatDateShort(machineLastUsed.date);
        } else {
          machineString = getMachineString(machine);
          dateString = formatDateTimeShort(machineLastUsed.date);
        }
        return (
          <tr key={machine.url}>
            <td>{machineString}</td>
            <td>{dateString}</td>
            <td>{initials}</td>
            <td>{getWorkTypeString(lastUsedWorkType)}</td>
            <td>
              {phone ? (
                <IconLinkButton href={`tel:${addDanishCountryPrefix(phone)}`} Icon={PhoneIcon} />
              ) : (
                <IconLinkButton Icon={PhoneIcon} />
              )}
            </td>
          </tr>
        );
      });

      return (
        <>
          <strong>
            <FormattedMessage defaultMessage="Sidst brugt af" />
          </strong>
          <table
            style={{
              textAlign: "left",
            }}
          >
            <thead style={{fontSize: 12, fontWeight: "normal"}}>
              <tr>
                <th>
                  <FormattedMessage defaultMessage="Mask." />
                </th>
                <th>
                  <FormattedMessage defaultMessage="Dato" />
                </th>
                <th>
                  <FormattedMessage defaultMessage="Medarb." />
                </th>
                <th>
                  <FormattedMessage defaultMessage="Område" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </>
      );
    } else {
      return undefined;
    }
  }, [
    customerSettings.showMachineLastUse,
    machineLastUsedArray,
    machineLookup,
    task.machineuseSet,
    userProfileArray,
    workTypeLookup,
  ]);

  const editMachineOperatorButton = useMemo(() => {
    if (customerSettings.allowMachineOperatorChangeOnTaskInfoPage) {
      return (
        <IconButton
          disabled={
            validated || (userIsOnlyMachineOperator && !userIsSeniorMachineOperator) || hasActivity
          }
          onClick={onChangeMachineOperatorClick}
        >
          <PencilIcon />
        </IconButton>
      );
    } else {
      return undefined;
    }
  }, [
    customerSettings.allowMachineOperatorChangeOnTaskInfoPage,
    hasActivity,
    onChangeMachineOperatorClick,
    userIsOnlyMachineOperator,
    userIsSeniorMachineOperator,
    validated,
  ]);

  const projectBlock = useMemo(() => {
    if (
      (customerSettings.showProjectNumberOnTaskInfoTab ||
        customerSettings.showProjectNameOnTaskInfoTab) &&
      task.project
    ) {
      const project = projectLookup(task.project);
      if (project) {
        const {alias, name, projectNumber} = project;
        const nameAndOrAlias = customerSettings.showProjectNameOnTaskInfoTab
          ? customerSettings.showProjectAlias && name && alias
            ? `${name}, ${alias}`
            : name || alias
          : "";
        const projectString =
          customerSettings.showProjectNumberOnTaskInfoTab &&
          customerSettings.showProjectNameOnTaskInfoTab
            ? `${projectNumber}: ${nameAndOrAlias}`
            : nameAndOrAlias;
        return (
          <Grid container>
            <Grid item>
              {customerSettings.projectLabelVariant === "PROJECT" ? (
                <FormattedMessage defaultMessage="Projekt" />
              ) : (
                <FormattedMessage defaultMessage="Sag" />
              )}
              <br />
              <h1>{projectString}</h1>
            </Grid>
          </Grid>
        );
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }, [
    customerSettings.projectLabelVariant,
    customerSettings.showProjectAlias,
    customerSettings.showProjectNameOnTaskInfoTab,
    customerSettings.showProjectNumberOnTaskInfoTab,
    projectLookup,
    task.project,
  ]);

  const effectiveTimeBlock = useMemo(() => {
    if (customerSettings.showTaskEffectiveTimeOnTaskInfoTab) {
      return (
        <Grid container>
          <Grid item>
            <FormattedMessage defaultMessage="Effektivitet" />
            <br />
            <h1>{taskEffectiveTime}</h1>
          </Grid>
        </Grid>
      );
    } else {
      return undefined;
    }
  }, [customerSettings.showTaskEffectiveTimeOnTaskInfoTab, taskEffectiveTime]);

  const showTaskEditIconButton = !userIsOtherMachineOperator && !userIsJobber;

  const hasOrderFiles = order && orderFileArray.some((orderFile) => orderFile.order === order.url);
  const hasCustomerFiles =
    customer && customerFileArray.some((customerFile) => customerFile.customer === customer.url);
  const hasProjectFiles =
    task.project && projectFileArray.some((projectFile) => projectFile.project === task.project);

  return (
    <div>
      <Grid container>
        <Grid item sm xs={12}>
          <Card style={{margin: "1em"}}>
            <CardContent>
              <div>
                {showTaskEditIconButton && (
                  <IconButton onClick={onGoToOrder} style={{float: "right"}}>
                    <PencilIcon />
                  </IconButton>
                )}
                <h5 style={{marginBottom: 24}}>
                  Oprettet af: {userInitials}
                  {customerSettings.taskPageCreatedDate
                    ? `, ${formatDateTime(task.created)}`
                    : null}
                </h5>
              </div>
              {completedText}
              {referenceNumber}
              {brugerdataReference}
              {projectBlock}
              {effectiveTimeBlock}
              <Grid container>
                <Grid item>
                  <FormattedMessage defaultMessage="Opgave" />
                  <br />
                  <h1>{workType}</h1>
                  {priceGroupBlock}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <FormattedMessage defaultMessage="Dato" />
                  <h2>{formatDate(date)}</h2>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <FormattedMessage defaultMessage="Forventet opstart" />
                  <h1>{time}</h1>
                </Grid>
              </Grid>
              {customerSettings.showArrivalAtLocationField && task.arrivalAtLocation ? (
                <Grid container>
                  <Grid item>
                    <FormattedMessage defaultMessage="Forventet opstart ved kunden" />
                    <br />
                    <h1>{task.arrivalAtLocation.substring(0, "HH:mm".length)}</h1>
                  </Grid>
                </Grid>
              ) : null}
              <Grid container>
                <Grid item>
                  <FormattedMessage defaultMessage="Opgaveansvarlig" />
                  <h4>
                    {responsibleInitials}
                    {editMachineOperatorButton}
                  </h4>
                </Grid>
              </Grid>
              {customerSettings.showColleaguesAtCustomer && !culture ? (
                <Grid container>
                  <Grid item>
                    <FormattedMessage
                      defaultMessage="Kolleger ved kunden denne dag:"
                      tagName="h4"
                    />
                    {otherMachineOperators}
                  </Grid>
                </Grid>
              ) : null}
              {!userIsOnlyMachineOperator && customerSettings.showInvoiceNote ? (
                <Grid container>
                  <Grid item>
                    <FormattedMessage defaultMessage="Faktura note:" tagName="h4" />
                    <div>
                      <Linkify>{task.invoiceNote}</Linkify>
                    </div>
                  </Grid>
                </Grid>
              ) : null}
              <Grid container>
                <Grid item>
                  <div style={{whiteSpace: "pre-line"}}>
                    <FormattedMessage defaultMessage="Noter fra administration:" tagName="h4" />
                    <div>{order && order.notes}</div>
                    <Linkify>
                      {task.notesFromManager}
                      {getJournalsForTask(task, order, journalArray).map((journal) => (
                        <div key={journal.url}>{journal.entry}</div>
                      ))}
                    </Linkify>
                  </div>
                </Grid>
              </Grid>
              {lastMachineUseBlock}
              {pdfButton}
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm xs={12}>
          {customerCard}
          {hasProjectFiles ? (
            <div style={{margin: "1em"}}>
              <ProjectFilesCard editable={false} projectUrl={task.project} />
            </div>
          ) : null}
          {hasOrderFiles &&
          customerSettings.orderShowOrderFiles &&
          customerSettings.enableOrders ? (
            <div style={{margin: "1em"}}>
              <OrderFilesCard editable={false} orderURL={order.url} />
            </div>
          ) : null}
          {hasCustomerFiles && customerSettings.customerFileUpload ? (
            <div style={{margin: "1em"}}>
              <CustomerFileListCard
                customerURL={customer.url}
                title={intl.formatMessage({defaultMessage: "Filer, kunde"})}
              />
            </div>
          ) : null}
          {cultureCard}
          {customerSettings.customerFields ? (
            <div style={{margin: "1em"}}>
              <TaskFields
                onRequestNotesDialogClose={onRequestNotesDialogClose}
                order={order}
                task={task}
              />
            </div>
          ) : null}
          {customerSettings.enableDocuments ? <GoToFilesButton /> : null}
        </Grid>
      </Grid>
    </div>
  );
});
