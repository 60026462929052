import type {SettingsModuleID} from "./settings-modules";
import {ProductVariant} from "./product-variants";

export const settingsGroupIdentifiers = [
  "omDenneKundeOpstning",
  "virksomhedStamoplysninger",
  "virksomhedKontaktoplysninger",
  "virksomhedLogo",
  "virksomhedProdukt",
  "virksomhedEmail",
  "informationModul",
  "informationGenerelt",
  "kalenderMedarbejderGenerelt",
  "kalenderMedarbejderKolonner",
  "kalenderMedarbejderOpgaveTopblok",
  "kalenderMedarbejderOpgaveBlok",
  "kalenderMedarbejderOpgaveBlokProjekt",
  "kalenderOrdreModul",
  "kalenderOrdre",
  "kalenderOpgaverModul",
  "kalenderOpgaver",
  "kalenderFravrModul",
  "kalenderFravr",
  "kalenderMaskinerModul",
  "kortModul",
  "kortGenerelt",
  "minDagsseddelModul",
  "minDagsseddelProjekt",
  "minDagseddelRdighed",
  "minArbejdstidModul",
  "minArbejdstidOversigt",
  "mineLnbilagModul",
  "minLnbilagOversigt",
  "ordreModul",
  "ordreGenerelt",
  "ordreOrdreliste",
  "ordreKladde",
  "ordreDetalje",
  "ordreDetaljerOpgavetabel",
  "ordreDetaljerResultatsum",
  "ordreDetaljerKnapper",
  "opgaverGenerelt",
  "opgaverOprettelseWizard",
  "opgaverOprettelseProjektRef",
  "opgaverDetaljer",
  "opgaverListe",
  "opgaverListeEgne",
  "opgaverListeInterne",
  "opgaverTopblok",
  "opgaverTid",
  "opgaverLog",
  "opgaverInfo",
  "opgaverInfoProjektRef",
  "opgaverMateriel",
  "opgaverFoto",
  "opgaverFakturering",
  "opgaverUdfrtDialog",
  "opgaverUdfrtDialogProjektRef",
  "opgaverAfslutningsKnapper",
  "opgaverInternFoto",
  "opgaverInternAfslutningsKnapper",
  "opgaverInternUdfrtDialog",
  "kunderModul",
  "kunderGenerelt",
  "kunderGenereltOpret",
  "kunderKontakter",
  "kunderListe",
  "kunderDetaljer",
  "kunderStatistik",
  "kunderOrdreoverblik",
  "kunderLog",
  "kunderMarker",
  "lagerModul",
  "lagerGenerelt",
  "dagsedlerGenerelt",
  "dagsedlerGps",
  "dagsedlerMarker",
  "dagsedlerNoter",
  "dagsedlerUdskrift",
  "dagsedlerKorrektion",
  "lnrapportGenerelt",
  "medarbejderGrupperModul",
  "medarbejdereMedarbejderliste",
  "medarbejdereProfil",
  "medarbejdereProfilLn",
  "dokumenterModul",
  "ruterModul",
  "ruterGenerelt",
  "vrkstedModul",
  "vrkstedGenerelt",
  "vrkstedOpgaver",
  "vrkstedMaskiner",
  "vrkstedTjekliste",
  "vrkstedAndet",
  "madbestillingModul",
  "madbestillingGenerelt",
  "indstillingerGenerelt",
  "indstillingerOversigt",
  "indstillingerOmrder",
  "indstillingerKultur",
  "indstillingerFavorit",
  "indstillingerMaskiner",
  "indstillingerPrislinjer",
  "indstillingerEgneOprettedePrislinjer",
  "indstillinerEnheder",
  "indstillingerLokationer",
  "indstillingerVarer",
  "indstillingerMaskinanalyse",
  "rapporterModuler",
  "rapporterOpgavestatistik",
  "rapporterMaskinanalyse",
  "rapporterTransport",
  "rapporterRutejournal",
  "omrdestatusModul",
  "omrdestatusGenerelt",
  "bilagModul",
  "bilagGenerelt",
  "systemGenerelt",
  "systemSvend",
  "systemAfdeling",
  "systemReferencenr",
  "systemInfoskrm",
  "projekterModul",
  "projekterGenerelt",
  "fakturaGenerelt",
  "fakturaSomCsv",
  "eConomicModul",
  "eConomicGenerelt",
  "eConomicGenereltProjekt",
  "eConomicFakturaProjekt",
  "eConomicFaktura",
  "c5Generelt",
  "navModul",
  "navGenerelt",
  "navProjekt",
  "navFakturaDagseddel",
  "brugerDataModul",
  "brugerDataGenerelt",
  "brugerDataFaktura",
  "displayGenerelt",
  "lnGenerelt",
  "lnFravrstyper",
  "datalnModul",
  "lessorModul",
  "lessorSpecialMaansson",
  "gratisalModul",
  "tidsknapperGenerelt",
  "logGenerel",
  "logGammel",
  "udgede",
  "ukendtGenerelt",
  "vrkstedCheckliste",
  "indstillingerFavoritvarer",
  "indstillingerFavoritmaskiner",
  "braendstoftillaegGenerelt",
  "braendstoftillaegEConomic",
  "stregkodeScanner",
  "economySystemActive",
  "uniconta",
  "unicontaDME",
  "economySystemResourceSync",
  "economySystemSendTaskRules",
  "economySystemSendTaskData",
  "economySystemInvoice",
  "economySystemInvoiceNotes",
] as const;

export type SettingsGroupID = (typeof settingsGroupIdentifiers)[number];

export interface SettingsGroupMetaData {
  readonly customOfficeVariants: readonly ProductVariant[];
  readonly description?: string;
  readonly documentationURL?: string;
  readonly module: SettingsModuleID;
  readonly title: string;
}

export type SettingsGroups = {
  readonly [S in SettingsGroupID]: SettingsGroupMetaData;
};

export const settingsGroups: SettingsGroups = {
  bilagGenerelt: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "bilag",
    title: "Bilag, generelt",
  },
  bilagModul: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "bilag",
    title: "Bilag, modul",
  },
  braendstoftillaegEConomic: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "braendstoftillaeg",
    title: "Brændstoftillæg, e-conomic",
  },
  braendstoftillaegGenerelt: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "braendstoftillaeg",
    title: "Brændstoftillæg, generelt",
  },
  brugerDataFaktura: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "brugerData",
    title: "BrugerData, faktura",
  },
  brugerDataGenerelt: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "brugerData",
    title: "BrugerData, generelt",
  },
  brugerDataModul: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "brugerData",
    title: "BrugerData, modul",
  },
  c5Generelt: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "c5",
    title: "C5, generelt",
  },
  dagsedlerGenerelt: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    documentationURL:
      "https://support.customoffice.dk/a/solutions/categories/30000035416/folders/30000057086?view=all",
    module: "dagsedler",
    title: "Dagsedler, generelt",
  },
  dagsedlerGps: {
    customOfficeVariants: ["PROFESSIONEL"],
    documentationURL:
      "https://support.customoffice.dk/a/solutions/categories/30000035416/folders/30000057093?view=all",
    module: "dagsedler",
    title: "Dagsedler, gps",
  },
  dagsedlerKorrektion: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "dagsedler",
    title: "Dagsedler, korrektion",
  },
  dagsedlerMarker: {
    customOfficeVariants: ["PROFESSIONEL"],
    documentationURL:
      "https://support.customoffice.dk/a/solutions/categories/30000035416/folders/30000057095?view=all",
    module: "dagsedler",
    title: "Dagsedler, marker",
  },
  dagsedlerNoter: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "dagsedler",
    title: "Dagsedler, noter",
  },
  dagsedlerUdskrift: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "dagsedler",
    title: "Dagsedler, udskrift",
  },
  datalnModul: {
    customOfficeVariants: [],
    documentationURL:
      "https://support.customoffice.dk/a/solutions/categories/30000035416/folders/30000057038?view=all",
    module: "dataLnLn",
    title: "Dataløn, modul",
  },
  displayGenerelt: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "display",
    title: "Display, generelt",
  },
  dokumenterModul: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "dokumenter",
    title: "Dokumenter, modul",
  },
  eConomicFaktura: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    documentationURL:
      "https://customoffice.freshdesk.com/a/solutions/categories/30000035416/folders/30000057102?view=all",
    module: "eConomic",
    title: "e-conomic, faktura",
  },
  eConomicFakturaProjekt: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "eConomic",
    title: "e-conomic, faktura, projekt",
  },
  eConomicGenerelt: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "eConomic",
    title: "e-conomic, generelt",
  },
  eConomicGenereltProjekt: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "eConomic",
    title: "e-conomic, generelt, projekt",
  },
  eConomicModul: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "eConomic",
    title: "e-conomic, modul",
  },
  economySystemActive: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    description: "Økonomisystem denne CustomOffice skal integrere op imod.",
    module: "economySystemIntegration",
    title: "Aktiver integration",
  },
  economySystemInvoice: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    description: "Regler for opbygning af faktura.",
    module: "economySystemIntegration",
    title: "Faktura",
  },
  economySystemInvoiceNotes: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    description: "Regler for opbygning af noter på faktura.",
    module: "economySystemIntegration",
    title: "Faktura noter",
  },
  economySystemResourceSync: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    description:
      "Disse indstillinger har p.t. kun effekt hvis den aktive integration er nævnt under 'Aktiver integration' i dette modul. Vi flytter gradvist indstillinger for integration af økonomisystemer hertil.",
    module: "economySystemIntegration",
    title: "Synkronisering",
  },
  economySystemSendTaskData: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    description: "Hvis intet er vinget af herunder, overføres der ikke noget til økonomisystemet.",
    module: "economySystemIntegration",
    title: "Sendes ved overførsel",
  },
  economySystemSendTaskRules: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    description: "Opgaver vil, hvis ikke andet angives, overføres så snart de godkendes.",
    module: "economySystemIntegration",
    title: "Regler for overførsel",
  },
  fakturaGenerelt: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    documentationURL:
      "https://customoffice.freshdesk.com/a/solutions/categories/30000035416/folders/30000057101?view=all",
    module: "faktura",
    title: "Faktura, generelt",
  },
  fakturaSomCsv: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "faktura",
    title: "Faktura, som CSV",
  },
  gratisalModul: {
    customOfficeVariants: [],
    module: "gratisalLn",
    title: "Gratisal, modul",
  },
  indstillinerEnheder: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "indstillinger",
    title: "Indstilliner, enheder",
  },
  indstillingerEgneOprettedePrislinjer: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "indstillinger",
    title: "Indstillinger, egne oprettede prislinjer",
  },
  indstillingerFavorit: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "indstillinger",
    title: "Indstillinger, favorit",
  },
  indstillingerFavoritmaskiner: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "indstillinger",
    title: "Indstillinger, favoritmaskiner",
  },
  indstillingerFavoritvarer: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "indstillinger",
    title: "Indstillinger, favoritvarer",
  },
  indstillingerGenerelt: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "indstillinger",
    title: "Indstillinger, generelt",
  },
  indstillingerKultur: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "indstillinger",
    title: "indstillinger, kultur",
  },
  indstillingerLokationer: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "indstillinger",
    title: "indstillinger, steder",
  },
  indstillingerMaskinanalyse: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "indstillinger",
    title: "Indstillinger, maskinanalyse",
  },
  indstillingerMaskiner: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "indstillinger",
    title: "Indstillinger, maskiner",
  },
  indstillingerOmrder: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "indstillinger",
    title: "Indstillinger, områder",
  },
  indstillingerOversigt: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "indstillinger",
    title: "Indstillinger, oversigt",
  },
  indstillingerPrislinjer: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "indstillinger",
    title: "Indstillinger, prislinjer",
  },
  indstillingerVarer: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "indstillinger",
    title: "Indstillinger, varer",
  },
  informationGenerelt: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    documentationURL:
      "https://customoffice.freshdesk.com/a/solutions/categories/30000035416/folders/30000055828?view=all",
    module: "information",
    title: "Information, generelt",
  },
  informationModul: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "information",
    title: "Information, modul",
  },
  kalenderFravr: {
    customOfficeVariants: ["PROFESSIONEL"],
    documentationURL:
      "https://customoffice.freshdesk.com/a/solutions/categories/30000035416/folders/30000057075?view=all",
    module: "kalender",
    title: "Kalender, fravær",
  },
  kalenderFravrModul: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "kalender",
    title: "Kalender, fravær, modul",
  },
  kalenderMaskinerModul: {
    customOfficeVariants: ["PROFESSIONEL"],
    documentationURL:
      "https://customoffice.freshdesk.com/a/solutions/categories/30000021304/folders/30000057076?view=all",
    module: "kalender",
    title: "Kalender, maskiner, modul",
  },
  kalenderMedarbejderGenerelt: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    documentationURL:
      "https://customoffice.freshdesk.com/a/solutions/categories/30000035416/folders/30000055830?view=all",
    module: "kalender",
    title: "Kalender, medarbejder, generelt",
  },
  kalenderMedarbejderKolonner: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "kalender",
    title: "Kalender, medarbejder, kolonner",
  },
  kalenderMedarbejderOpgaveBlok: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "kalender",
    title: "Kalender, medarbejder, opgave-blok",
  },
  kalenderMedarbejderOpgaveBlokProjekt: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "kalender",
    title: "Kalender, medarbejder, opgave-blok, projekt",
  },
  kalenderMedarbejderOpgaveTopblok: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "kalender",
    title: "Kalender, medarbejder, opgave-topblok",
  },
  kalenderOpgaver: {
    customOfficeVariants: ["PROFESSIONEL"],
    documentationURL:
      "https://customoffice.freshdesk.com/a/solutions/categories/30000035416/folders/30000057074?view=all",
    module: "kalender",
    title: "Kalender, opgaver",
  },
  kalenderOpgaverModul: {
    customOfficeVariants: ["PROFESSIONEL"],
    documentationURL:
      "https://customoffice.freshdesk.com/a/solutions/categories/30000035416/folders/30000057074?view=all",
    module: "kalender",
    title: "Kalender, opgaver, modul",
  },
  kalenderOrdre: {
    customOfficeVariants: ["PROFESSIONEL"],
    documentationURL:
      "https://customoffice.freshdesk.com/a/solutions/categories/30000035416/folders/30000057066?view=all",
    module: "kalender",
    title: "Kalender, ordre",
  },
  kalenderOrdreModul: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "kalender",
    title: "Kalender, ordre, modul",
  },
  kortGenerelt: {
    customOfficeVariants: ["PROFESSIONEL"],
    documentationURL:
      "https://support.customoffice.dk/a/solutions/categories/30000035416/folders/30000055831?view=all",
    module: "kort",
    title: "Kort, generelt",
  },
  kortModul: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "kort",
    title: "Kort, modul",
  },
  kunderDetaljer: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    documentationURL: "https://support.customoffice.dk/da/support/solutions/folders/30000057079",
    module: "kunder",
    title: "Kunder, detaljer",
  },
  kunderGenerelt: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    documentationURL: "https://support.customoffice.dk/da/support/solutions/folders/30000055835",
    module: "kunder",
    title: "Kunder, generelt",
  },
  kunderGenereltOpret: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    documentationURL: "https://support.customoffice.dk/da/support/solutions/folders/30000057084",
    module: "kunder",
    title: "Kunder, generelt, opret",
  },
  kunderKontakter: {
    customOfficeVariants: ["STANDARD"],
    module: "kunder",
    title: "Kunder, kontakter",
  },
  kunderListe: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    documentationURL: "https://support.customoffice.dk/da/support/solutions/folders/30000057078",
    module: "kunder",
    title: "Kunder, liste",
  },
  kunderLog: {
    customOfficeVariants: ["PROFESSIONEL"],
    documentationURL: "https://support.customoffice.dk/da/support/solutions/folders/30000057082",
    module: "kunder",
    title: "kunder, log",
  },
  kunderMarker: {
    customOfficeVariants: ["PROFESSIONEL"],
    documentationURL: "https://support.customoffice.dk/da/support/solutions/folders/30000057083",
    module: "kunder",
    title: "Kunder, marker",
  },
  kunderModul: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "kunder",
    title: "Kunder, modul",
  },
  kunderOrdreoverblik: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    documentationURL: "https://support.customoffice.dk/da/support/solutions/folders/30000057081",
    module: "kunder",
    title: "Kunder, ordreoverblik",
  },
  kunderStatistik: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    documentationURL: "https://support.customoffice.dk/da/support/solutions/folders/30000057080",
    module: "kunder",
    title: "Kunder, statistik",
  },
  lagerGenerelt: {
    customOfficeVariants: ["PROFESSIONEL"],
    documentationURL:
      "https://customoffice.freshdesk.com/a/solutions/categories/30000035416/folders/30000056943?view=all",
    module: "lager",
    title: "Lager, generelt",
  },
  lagerModul: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "lager",
    title: "Lager, modul",
  },
  lessorModul: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "lessorLn",
    title: "Lessor, modul",
  },
  lessorSpecialMaansson: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "maanssonLn",
    title: "Lessor, special, maansson",
  },
  lnFravrstyper: {
    customOfficeVariants: ["PROFESSIONEL"],
    documentationURL:
      "https://customoffice.freshdesk.com/a/solutions/categories/30000035416/folders/30000057087?view=all",
    module: "ln",
    title: "Løn, fraværstyper",
  },
  lnGenerelt: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "ln",
    title: "Løn, generelt",
  },
  lnrapportGenerelt: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    documentationURL:
      "https://customoffice.freshdesk.com/a/solutions/categories/30000035416/folders/30000055837?view=all",
    module: "lnrapport",
    title: "lønrapport, generelt",
  },
  logGammel: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "log",
    title: "Log, gammel",
  },
  logGenerel: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "log",
    title: "Log, generel",
  },
  madbestillingGenerelt: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "madbestilling",
    title: "Madbestilling, generelt",
  },
  madbestillingModul: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "madbestilling",
    title: "Madbestilling, modul",
  },
  medarbejdereMedarbejderliste: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "medarbejdere",
    title: "Medarbejdere, medarbejderliste",
  },
  medarbejdereProfil: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "medarbejdere",
    title: "Medarbejdere, profil",
  },
  medarbejdereProfilLn: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "medarbejdere",
    title: "Medarbejdere, profil, løn",
  },
  medarbejderGrupperModul: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "medarbejderGrupper",
    title: "Medarbejdergruppe, modul",
  },
  minArbejdstidModul: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "minArbejdstid",
    title: "Min arbejdstid, modul",
  },
  minArbejdstidOversigt: {
    customOfficeVariants: ["PROFESSIONEL"],
    documentationURL:
      "https://customoffice.freshdesk.com/a/solutions/categories/30000035416/folders/30000057069?view=all",
    module: "minArbejdstid",
    title: "Min arbejdstid, oversigt",
  },
  minDagseddelRdighed: {
    customOfficeVariants: ["PROFESSIONEL"],
    documentationURL:
      "https://support.customoffice.dk/a/solutions/categories/30000035416/folders/30000057077?view=all",
    module: "minDagseddel",
    title: "Min dagseddel, rådighed",
  },
  minDagsseddelModul: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "minDagseddel",
    title: "Min dagsseddel, modul",
  },
  minDagsseddelProjekt: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "minDagseddel",
    title: "Min dagsseddel, projekt",
  },
  mineLnbilagModul: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "mineLnbilag",
    title: "Mine lønbilag, modul",
  },
  minLnbilagOversigt: {
    customOfficeVariants: ["PROFESSIONEL"],
    documentationURL:
      "https://customoffice.freshdesk.com/a/solutions/categories/30000035416/folders/30000057068?view=all",
    module: "mineLnbilag",
    title: "Min lønbilag, oversigt",
  },
  navFakturaDagseddel: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    documentationURL:
      "https://customoffice.freshdesk.com/a/solutions/categories/30000035416/folders/30000057103?view=all",
    module: "nav",
    title: "NAV, faktura (dagseddel)",
  },
  navGenerelt: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "nav",
    title: "NAV, generelt",
  },
  navModul: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "nav",
    title: "NAV, modul",
  },
  navProjekt: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "nav",
    title: "NAV, projekt",
  },
  omDenneKundeOpstning: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    documentationURL:
      "https://support.customoffice.dk/a/solutions/categories/30000035416/folders/30000057063?view=all",
    module: "konsulentOverblik",
    title: "Om denne kunde/opsætning",
  },
  omrdestatusGenerelt: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "omrdestatus",
    title: "Områdestatus, generelt",
  },
  omrdestatusModul: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "omrdestatus",
    title: "Områdestatus, modul",
  },
  opgaverAfslutningsKnapper: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "opgaver",
    title: "Opgaver, afslutnings-knapper",
  },
  opgaverDetaljer: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    documentationURL:
      "https://customoffice.freshdesk.com/a/solutions/categories/30000035416/folders/30000057085?view=all",
    module: "opgaver",
    title: "Opgaver, detaljer",
  },
  opgaverFakturering: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "opgaver",
    title: "Opgaver, fakturering",
  },
  opgaverFoto: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "opgaver",
    title: "Opgaver, foto",
  },
  opgaverGenerelt: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "opgaver",
    title: "Opgaver, generelt",
  },
  opgaverInfo: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "opgaver",
    title: "Opgaver, info",
  },
  opgaverInfoProjektRef: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "opgaver",
    title: "Opgaver, info, projekt / ref",
  },
  opgaverInternAfslutningsKnapper: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "opgaver",
    title: "Opgaver, intern, afslutnings-knapper",
  },
  opgaverInternFoto: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "opgaver",
    title: "Opgaver, intern, foto",
  },
  opgaverInternUdfrtDialog: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "opgaver",
    title: "Opgaver, intern, udført-dialog",
  },
  opgaverListe: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "opgaver",
    title: "Opgaver, liste",
  },
  opgaverListeEgne: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "opgaver",
    title: "Opgaver, liste, egne",
  },
  opgaverListeInterne: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "opgaver",
    title: "Opgaver, liste, interne",
  },
  opgaverLog: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "opgaver",
    title: "Opgaver, log",
  },
  opgaverMateriel: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "opgaver",
    title: "Opgaver, materiel",
  },
  opgaverOprettelseProjektRef: {
    customOfficeVariants: ["PROFESSIONEL"],
    documentationURL:
      "https://customoffice.freshdesk.com/a/solutions/categories/30000035416/folders/30000057100?view=all",
    module: "opgaver",
    title: "Opgaver, oprettelse, projekt / ref",
  },
  opgaverOprettelseWizard: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    documentationURL:
      "https://customoffice.freshdesk.com/a/solutions/categories/30000035416/folders/30000057085?view=all",
    module: "opgaver",
    title: "Opgaver, oprettelse, wizard",
  },
  opgaverTid: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "opgaver",
    title: "Opgaver, tid",
  },
  opgaverTopblok: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "opgaver",
    title: "Opgaver, topblok",
  },
  opgaverUdfrtDialog: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "opgaver",
    title: "Opgaver, udført-dialog",
  },
  opgaverUdfrtDialogProjektRef: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "opgaver",
    title: "Opgaver, udført-dialog, projekt / ref",
  },
  ordreDetalje: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "ordre",
    title: "Ordre, detalje",
  },
  ordreDetaljerKnapper: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "ordre",
    title: "Ordre, detaljer, knapper",
  },
  ordreDetaljerOpgavetabel: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "ordre",
    title: "Ordre, detaljer, opgavetabel",
  },
  ordreDetaljerResultatsum: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "ordre",
    title: "Ordre, detaljer, resultatsum",
  },
  ordreGenerelt: {
    customOfficeVariants: ["PROFESSIONEL"],
    documentationURL:
      "https://customoffice.freshdesk.com/a/solutions/categories/30000035416/folders/30000055833?view=all",
    module: "ordre",
    title: "Ordre, generelt",
  },
  ordreKladde: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "ordre",
    title: "Ordre, kladde",
  },
  ordreModul: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "ordre",
    title: "Ordre, modul",
  },
  ordreOrdreliste: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "ordre",
    title: "Ordre, Ordreliste",
  },
  projekterGenerelt: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "projekter",
    title: "Projekter, generelt",
  },
  projekterModul: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "projekter",
    title: "Projekter, modul",
  },
  rapporterMaskinanalyse: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "rapporter",
    title: "Rapporter, maskinanalyse",
  },
  rapporterModuler: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "rapporter",
    title: "Rapporter, moduler",
  },
  rapporterOpgavestatistik: {
    customOfficeVariants: ["PROFESSIONEL"],
    documentationURL: "https://support.customoffice.dk/a/solutions/articles/30000039004",
    module: "rapporter",
    title: "Rapporter, opgavestatistik",
  },
  rapporterRutejournal: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "rapporter",
    title: "Rapporter, rutejournal",
  },
  rapporterTransport: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "rapporter",
    title: "Rapporter, transport",
  },
  ruterGenerelt: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "ruter",
    title: "Ruter, generelt",
  },
  ruterModul: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "ruter",
    title: "Ruter, modul",
  },
  stregkodeScanner: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "stregkodeScanner",
    title: "Stregkode/QR-kode scanner",
  },
  systemAfdeling: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "system",
    title: "System, afdeling",
  },
  systemGenerelt: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "system",
    title: "System, generelt",
  },
  systemInfoskrm: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "system",
    title: "System, infoskærm",
  },
  systemReferencenr: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "system",
    title: "System, referencenr.",
  },
  systemSvend: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "system",
    title: "System, Svend",
  },
  tidsknapperGenerelt: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    documentationURL:
      "https://customoffice.freshdesk.com/a/solutions/categories/30000035416/folders/30000057099?view=all",
    module: "tidsknapper",
    title: "Tidsknapper, generelt",
  },
  udgede: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "udgede",
    title: "Udgåede?",
  },
  ukendtGenerelt: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "udgede",
    title: "Ukendt, generelt",
  },
  uniconta: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "economySystemIntegration",
    title: "Uniconta",
  },
  unicontaDME: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    description: "Vigtigt: ved aktivering skal alle settings i denne gruppe udfyldes!",
    module: "economySystemIntegration",
    title: "Uniconta DME",
  },
  virksomhedEmail: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "virksomhed",
    title: "Virksomhed, email",
  },
  virksomhedKontaktoplysninger: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    documentationURL:
      "https://support.customoffice.dk/a/solutions/categories/30000035416/folders/30000057064?view=all",
    module: "virksomhed",
    title: "Virksomhed, kontaktoplysninger",
  },
  virksomhedLogo: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    documentationURL:
      "https://support.customoffice.dk/a/solutions/categories/30000035416/folders/30000057065?view=all",
    module: "virksomhed",
    title: "Virksomhed, logo",
  },
  virksomhedProdukt: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    module: "virksomhed",
    title: "Virksomhed, produkt",
  },
  virksomhedStamoplysninger: {
    customOfficeVariants: ["STANDARD", "PROFESSIONEL"],
    documentationURL:
      "https://support.customoffice.dk/a/solutions/categories/30000035416/folders/30000057063?view=all",
    module: "virksomhed",
    title: "Virksomhed, stamoplysninger",
  },
  vrkstedAndet: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "vrksted",
    title: "Værksted, andet",
  },
  vrkstedCheckliste: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "vrksted",
    title: "Værksted, checkliste",
  },
  vrkstedGenerelt: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "vrksted",
    title: "Værksted, generelt",
  },
  vrkstedMaskiner: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "vrksted",
    title: "Værksted, maskiner",
  },
  vrkstedModul: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "vrksted",
    title: "Værksted, modul",
  },
  vrkstedOpgaver: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "vrksted",
    title: "Værksted, opgaver",
  },
  vrkstedTjekliste: {
    customOfficeVariants: ["PROFESSIONEL"],
    module: "vrksted",
    title: "Værksted, tjekliste",
  },
};
