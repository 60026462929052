import {SECOND_MILLISECONDS} from "@co-common-libs/utils";
import React, {useCallback, useEffect, useState} from "react";
import {useThrottledCallback} from "use-debounce";
import {TrimTextField, TrimTextFieldProps} from "./trim-text-field";

const THROTTLE_WAIT_MS = SECOND_MILLISECONDS;

interface ThrottledTextFieldProps extends TrimTextFieldProps {
  onChange?: ((value: string) => void) | undefined;
  value?: string | undefined;
  waitMilliseconds?: number;
}

export function ThrottledTextField({
  onChange: onChangeFromProps,
  value: valueFromProps,
  waitMilliseconds = THROTTLE_WAIT_MS,
  ...others
}: ThrottledTextFieldProps): React.JSX.Element {
  const [value, setValue] = useState(valueFromProps ?? "");
  const throttledOnChange = useThrottledCallback(
    (newValue: string) => {
      if (onChangeFromProps) {
        onChangeFromProps(newValue);
      }
    },
    waitMilliseconds,
    {leading: false, trailing: true},
  );

  const handleChange = useCallback(
    (newValue: string) => {
      setValue(newValue);
      throttledOnChange(newValue);
    },
    [throttledOnChange],
  );

  useEffect(() => {
    // flush on unmount
    return () => throttledOnChange.flush();
  }, [throttledOnChange]);

  return <TrimTextField onChange={handleChange} value={value} {...others} />;
}
