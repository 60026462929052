import {Config} from "@co-common-libs/config";
import {
  CustomerUrl,
  Location,
  LocationUrl,
  Machine,
  MachineUrl,
  Order,
  Patch,
  PatchUnion,
  PriceGroup,
  PriceGroupUrl,
  Project,
  ReportingSpecification,
  ReportingSpecificationUrl,
  Role,
  Task,
  TaskUrl,
  Timer,
  TimerStart,
  TimerUrl,
  urlToId,
  UserProfile,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {
  formatDuration,
  getNormalisedDeviceTimestamp,
  getWorkTypeString,
} from "@co-common-libs/resources-utils";
import {notUndefined, valuesSortedByOrderMember} from "@co-common-libs/utils";
import {DateField, TimeField} from "@co-frontend-libs/components";
import {
  ConnectedLogLegalExternalWorkTypeDialog,
  ConnectedLogLegalPriceGroupDialog,
} from "@co-frontend-libs/connected-components";
import {PathTemplate} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {IconButton, TableCell, TableCellProps, TableRow} from "@material-ui/core";
import {common as commonColors, grey} from "@material-ui/core/colors";
import {TaskStatusIcon} from "app-components";
import {
  computeIntervalsTruncated,
  computeIntervalSums,
  focusButton,
  getLocationString,
  getWorkplaceString,
  mergeIntervals,
  workTypeChangeBlocked,
  WorkTypeChangeBlockedReason,
} from "app-utils";
import {bind} from "bind-decorator";
import _ from "lodash";
import ClipboardTextIcon from "mdi-react/ClipboardTextIcon";
import ContentCopyIcon from "mdi-react/ContentCopyIcon";
import PencilIcon from "mdi-react/PencilIcon";
import TimerIcon from "mdi-react/TimerIcon";
import React from "react";
import {MoveTask} from "./move-task";

const taskEntryPropKeysExceptStuff = [
  "currentRole",
  "customerSettings",
  "machineLookup",
  "machineOperatorAbsent",
  "striped",
  "taskArray",
  "timerArray",
  "timerStartArray",
  "update",
  "workTypeLookup",
  "onRequestWorkplaceEdit",
  "onRequestEstimatedTimeEdit",
  "onRequestMachineAdd",
  "onRequestMachineOperatorEdit",
  "machineOperatorProfile",
  "priceGroup",
  "workType",
] as const;

interface TaskEntryProps {
  allowMachineUse: boolean;
  allowProjectChange: boolean;
  currentRole: Role | null;
  customerSettings: Config;
  customerURL: CustomerUrl | null;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  locationLookup: (url: LocationUrl) => Location | undefined;
  machineList: readonly Machine[];
  machineLookup: (url: MachineUrl) => Machine | undefined;
  machineOperatorAbsent: boolean;
  machineOperatorHasMultipleTasks: boolean;
  machineOperatorProfile: UserProfile | undefined;
  machinePriceGroups: readonly PriceGroup[];
  onRequestEstimatedTimeEdit: (taskURL: TaskUrl, button: HTMLButtonElement | null) => void;
  onRequestMachineAdd: (taskURL: TaskUrl, button: HTMLButtonElement | null) => void;
  onRequestMachineOperatorEdit: (taskURL: TaskUrl, button: HTMLButtonElement | null) => void;
  onRequestProjectEdit: (taskURL: TaskUrl, button: HTMLButtonElement | null) => void;
  onRequestWorkplaceEdit: (taskURL: TaskUrl, button: HTMLButtonElement | null) => void;
  onTaskCopy: (taskURL: TaskUrl) => void;
  onWorkTypeChangeBlocked: (reason: WorkTypeChangeBlockedReason) => void;
  order: Order;
  priceGroup?: PriceGroup | undefined;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  project?: Project | undefined;
  reportingSpecificationArray: readonly ReportingSpecification[];
  reportingSpecificationLookup: (
    url: ReportingSpecificationUrl,
  ) => ReportingSpecification | undefined;
  striped: boolean;
  task: Task;
  taskArray: readonly Task[];
  timerArray: readonly Timer[];
  timerStartArray: readonly TimerStart[];
  update: (url: string, patch: PatchUnion) => void;
  workType?: WorkType | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface TaskEntryState {
  priceGroupDialogOpen: boolean;
  workTypeDialogOpen: boolean;
}

class TaskEntry extends React.Component<TaskEntryProps> {
  estimatedTimeButtonRef = React.createRef<HTMLButtonElement>();
  machineButtonRef = React.createRef<HTMLButtonElement>();
  machineOperatorButtonRef = React.createRef<HTMLButtonElement>();
  priceGroupButtonRef = React.createRef<HTMLButtonElement>();
  projectButtonRef = React.createRef<HTMLButtonElement>();
  state: TaskEntryState = {
    priceGroupDialogOpen: false,
    workTypeDialogOpen: false,
  };
  workplaceButtonRef = React.createRef<HTMLButtonElement>();
  workTypeButtonRef = React.createRef<HTMLButtonElement>();
  getIntervals(): readonly {
    readonly fromTimestamp: string;
    readonly timer: TimerUrl | null;
    readonly toTimestamp: string;
  }[] {
    const {task} = this.props;
    const computedIntervals = task.recordedInC5
      ? task.computedTimeSet || []
      : computeIntervalsTruncated(this.getTimerStarts());
    const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
    const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
    const intervals = mergeIntervals(
      computedIntervals,
      correctionIntervals,
      managerCorrectionIntervals,
    );
    return intervals;
  }

  getTimerStarts(): TimerStart[] {
    const {task} = this.props;
    const taskURL = task.url;
    return _.sortBy(
      this.props.timerStartArray.filter((instance) => instance.task === taskURL),
      getNormalisedDeviceTimestamp,
    );
  }
  @bind
  handleAddressSelectClick(): void {
    if (this.props.task.validatedAndRecorded) {
      return;
    }
    const taskURL = this.props.task.url;
    this.props.onRequestWorkplaceEdit(taskURL, this.workplaceButtonRef.current);
  }
  @bind
  handleCopyClick(): void {
    this.props.onTaskCopy(this.props.task.url);
  }
  @bind
  handleDateChange(newValue: string | null): void {
    const {order, task, taskArray} = this.props;
    this.props.update(task.url, [{member: "date", value: newValue}]);
    if (taskArray.filter((t) => t.order === order.url).length === 1) {
      // Update order with task date
      this.props.update(order.url, [{member: "date", value: newValue}]);
    }
  }
  @bind
  handleGoToTaskClick(): void {
    const {task} = this.props;
    const id = urlToId(task.url);
    this.props.go("/taskDetails/:id", {id});
  }
  @bind
  handleGoToTaskInstanceClick(): void {
    const {task} = this.props;
    const id = urlToId(task.url);
    this.props.go("/task/:id", {id});
  }
  @bind
  handleMachineOperatorSelectClick(): void {
    if (this.props.task.validatedAndRecorded) {
      return;
    }
    const {reportApproved, validatedAndRecorded} = this.props.task;
    const disableMachineOperatorSelection =
      !this.props.customerSettings.allowCustomerTaskEmployeeChange ||
      validatedAndRecorded ||
      reportApproved ||
      this.hasActivity();
    const taskURL = this.props.task.url;
    if (!disableMachineOperatorSelection) {
      this.props.onRequestMachineOperatorEdit(taskURL, this.machineOperatorButtonRef.current);
    }
  }
  @bind
  handleMachinesAddClick(): void {
    const {reportApproved, validatedAndRecorded} = this.props.task;
    if (!this.props.allowMachineUse || validatedAndRecorded || reportApproved) {
      return;
    }
    const taskURL = this.props.task.url;
    this.props.onRequestMachineAdd(taskURL, this.machineButtonRef.current);
  }
  @bind
  handleMinutesExpectedTotalTaskDurationEditClick(): void {
    const {reportApproved, validatedAndRecorded} = this.props.task;
    if (validatedAndRecorded || reportApproved) {
      return;
    }
    const taskURL = this.props.task.url;
    this.props.onRequestEstimatedTimeEdit(taskURL, this.estimatedTimeButtonRef.current);
  }
  @bind
  handlePriceGroupDialogCancel(): void {
    focusButton(this.priceGroupButtonRef);
    this.setState({priceGroupDialogOpen: false});
  }
  @bind
  handlePriceGroupDialogOk(priceGroupURL: PriceGroupUrl): void {
    focusButton(this.priceGroupButtonRef);
    this.setState({priceGroupDialogOpen: false});
    const patch: Patch<Task> = [{member: "priceGroup", value: priceGroupURL}];
    this.props.update(this.props.task.url, patch);
  }
  @bind
  handlePriceGroupSelectClick(): void {
    const {reportApproved, validatedAndRecorded} = this.props.task;
    if (validatedAndRecorded || reportApproved) {
      return;
    }
    const {
      machineLookup,
      priceGroupLookup,
      reportingSpecificationArray,
      reportingSpecificationLookup,
      task,
      timerArray,
      workTypeLookup,
    } = this.props;
    const intervals = this.getIntervals();
    const now = new Date();
    now.setUTCMilliseconds(0);
    const timerMinutes = computeIntervalSums(intervals, now);
    const workTypeChangeBlockedReason = workTypeChangeBlocked(
      task,
      timerMinutes,
      this.props.customerSettings,
      {
        machineLookup,
        priceGroupLookup,
        reportingSpecificationArray,
        reportingSpecificationLookup,
        timerArray,
        workTypeLookup,
      },
    );
    if (workTypeChangeBlockedReason) {
      this.props.onWorkTypeChangeBlocked(workTypeChangeBlockedReason);
    } else {
      this.setState({priceGroupDialogOpen: true});
    }
  }
  @bind
  handleProjectSelectClick(): void {
    if (
      this.props.task.validatedAndRecorded ||
      this.props.task.reportApproved ||
      !this.props.allowProjectChange
    ) {
      return;
    }
    const taskURL = this.props.task.url;
    this.props.onRequestProjectEdit(taskURL, this.projectButtonRef.current);
  }
  @bind
  handleTimeChange(newValue: string | null): void {
    const {task} = this.props;
    this.props.update(task.url, [{member: "time", value: newValue}]);
  }
  @bind
  handleWorkTypeDialogCancel(): void {
    focusButton(this.workTypeButtonRef.current);
    this.setState({workTypeDialogOpen: false});
  }
  @bind
  handleWorkTypeDialogOk(workTypeURL: WorkTypeUrl): void {
    focusButton(this.workTypeButtonRef.current);
    this.setState({workTypeDialogOpen: false});
    const patch: Patch<Task> = [
      {member: "priceGroup", value: null},
      {member: "workType", value: workTypeURL},
    ];
    this.props.update(this.props.task.url, patch);
  }
  @bind
  handleWorkTypeSelectClick(): void {
    const {reportApproved, validatedAndRecorded} = this.props.task;
    if (validatedAndRecorded || reportApproved) {
      return;
    }
    // FIXME: disallow change if work type "locked"
    const {
      machineLookup,
      priceGroupLookup,
      reportingSpecificationArray,
      reportingSpecificationLookup,
      task,
      timerArray,
      workTypeLookup,
    } = this.props;
    const intervals = this.getIntervals();
    const now = new Date();
    now.setUTCMilliseconds(0);
    const timerMinutes = computeIntervalSums(intervals, now);
    const workTypeChangeBlockedReason = workTypeChangeBlocked(
      task,
      timerMinutes,
      this.props.customerSettings,
      {
        machineLookup,
        priceGroupLookup,
        reportingSpecificationArray,
        reportingSpecificationLookup,
        timerArray,
        workTypeLookup,
      },
    );
    if (workTypeChangeBlockedReason) {
      this.props.onWorkTypeChangeBlocked(workTypeChangeBlockedReason);
    } else {
      this.setState({workTypeDialogOpen: true});
    }
  }
  hasActivity(): boolean {
    const intervals = this.getIntervals();
    return !!intervals.length;
  }
  render(): React.JSX.Element {
    const {
      allowMachineUse,
      allowProjectChange,
      customerSettings,
      machineList,
      machineOperatorHasMultipleTasks,
      machineOperatorProfile,
      machinePriceGroups,
      order,
      priceGroup,
      project,
      striped,
      task,
      workType,
    } = this.props;
    const role = this.props.currentRole;
    const isManager = role && role.manager;
    const workTypeString = getWorkTypeString(workType);
    const {date} = task;

    const machineOperatorInitials = machineOperatorProfile ? machineOperatorProfile.alias : null;
    let machineOperatorInitialsBlock;
    if (machineOperatorInitials) {
      machineOperatorInitialsBlock = (
        <span
          key={`${this.props.machineOperatorAbsent}`}
          style={
            this.props.machineOperatorAbsent
              ? {color: "red"}
              : machineOperatorHasMultipleTasks
                ? {color: "orange"}
                : {}
          }
        >
          {machineOperatorInitials}
        </span>
      );
    }
    const machines = machineList.map((machine) => machine.c5_machine).join(", ");
    const location = task.relatedWorkplace
      ? this.props.locationLookup(task.relatedWorkplace)
      : null;
    const addressString = location
      ? getWorkplaceString(location)
      : (task.address || "").split("\n").join(", ");
    let priceGroupName;
    if (customerSettings.orderEntryTaskTableAllwaysShowMachinePriceGroups) {
      priceGroupName = machinePriceGroups.map((p) => p.name).join(", ");
    } else {
      priceGroupName = priceGroup?.name || "";
    }
    const {reportApproved, validatedAndRecorded} = task;
    const {noExternalTaskWorkType} = customerSettings;
    const tableRowColumnStyle: React.CSSProperties = striped
      ? {
          backgroundColor: "rgba(129, 212, 249, 0.4)",
        }
      : {};

    const dialogs: React.JSX.Element[] = [];

    let priceGroupCell: React.JSX.Element | undefined;
    if (customerSettings.orderEntryShowPriceGroup) {
      if (
        !customerSettings.noExternalTaskWorkType &&
        customerSettings.enableExternalTaskWorkTypeAndVariantSwitch
      ) {
        const priceGroupChangeDisabled = validatedAndRecorded || reportApproved || !workType;
        const cellOptionalClickHandlerProps: TableCellProps = {};
        if (!priceGroupChangeDisabled) {
          cellOptionalClickHandlerProps.onClick = this.handlePriceGroupSelectClick;
        }
        priceGroupCell = (
          <TableCell
            key="pricegroup"
            ref={this.priceGroupButtonRef}
            style={{
              ...tableRowColumnStyle,
              cursor: priceGroupChangeDisabled ? "not-allowed" : "pointer",
            }}
            {...cellOptionalClickHandlerProps}
          >
            {priceGroupName ? (
              priceGroupName
            ) : (
              <IconButton disabled={priceGroupChangeDisabled}>
                <PencilIcon />
              </IconButton>
            )}
          </TableCell>
        );
        dialogs.push(
          <ConnectedLogLegalPriceGroupDialog
            customerUrl={this.props.customerURL}
            key="price-group-dialog"
            onCancel={this.handlePriceGroupDialogCancel}
            onOk={this.handlePriceGroupDialogOk}
            open={this.state.priceGroupDialogOpen}
            task={task}
            workTypeURL={task.workType || undefined}
          />,
        );
      } else {
        priceGroupCell = (
          <TableCell key="pricegroup" style={tableRowColumnStyle}>
            {priceGroupName}
          </TableCell>
        );
      }
    }
    let workplaceCell: React.JSX.Element | undefined;
    if (customerSettings.orderEntryShowWorkPlace) {
      workplaceCell = (
        <TableCell
          key="workplace"
          onClick={this.handleAddressSelectClick}
          ref={this.workplaceButtonRef}
          style={{
            ...tableRowColumnStyle,
            cursor: validatedAndRecorded || reportApproved ? "not-allowed" : "pointer",
          }}
        >
          {location || addressString ? (
            addressString
          ) : (
            <IconButton disabled={validatedAndRecorded || reportApproved}>
              <PencilIcon />
            </IconButton>
          )}
        </TableCell>
      );
    }
    let pickupDeliveryCells: React.JSX.Element | undefined;
    if (customerSettings.showLogPickupAndDeliveryColumnsOnOrderEntryTaskList) {
      const pickupLocations = valuesSortedByOrderMember(task.reportingLocations).filter(
        (x) => x.type === "pickup",
      );
      const deliveryLocations = valuesSortedByOrderMember(task.reportingLocations).filter(
        (x) => x.type === "delivery",
      );
      pickupDeliveryCells = (
        <React.Fragment key="pickup-delivery">
          <TableCell style={{...tableRowColumnStyle, whiteSpace: "pre-wrap"}}>
            {pickupLocations
              .map((logLocation) => {
                const locationInstance =
                  logLocation.location && this.props.locationLookup(logLocation.location);
                return locationInstance ? getLocationString(locationInstance) : "";
              })
              .filter(Boolean)
              .join("\n")}
          </TableCell>
          <TableCell style={{...tableRowColumnStyle, whiteSpace: "pre-wrap"}}>
            {deliveryLocations
              .map((logLocation) => {
                const locationInstance =
                  logLocation.location && this.props.locationLookup(logLocation.location);
                return locationInstance ? getLocationString(locationInstance) : "";
              })
              .filter(Boolean)
              .join("\n")}
          </TableCell>
        </React.Fragment>
      );
    }
    let projectCell: React.JSX.Element | undefined;
    if (customerSettings.enableProjects) {
      projectCell = (
        <TableCell
          key="project"
          onClick={this.handleProjectSelectClick}
          ref={this.projectButtonRef}
          style={{
            ...tableRowColumnStyle,
            cursor:
              validatedAndRecorded || reportApproved || !allowProjectChange
                ? "not-allowed"
                : "pointer",
          }}
        >
          {project ? (
            `${project.projectNumber}: ${project.name}`
          ) : (
            <IconButton disabled={validatedAndRecorded || reportApproved || !allowProjectChange}>
              <PencilIcon />
            </IconButton>
          )}
        </TableCell>
      );
    }
    const disableMachineOperatorSelection =
      !customerSettings.allowCustomerTaskEmployeeChange ||
      validatedAndRecorded ||
      reportApproved ||
      this.hasActivity();
    const machineOperatorCell = (
      <TableCell
        key="machineoperator"
        onClick={this.handleMachineOperatorSelectClick}
        ref={this.machineOperatorButtonRef}
        style={{
          ...tableRowColumnStyle,
          color: disableMachineOperatorSelection ? grey[500] : commonColors.black,
          cursor: disableMachineOperatorSelection ? "not-allowed" : "pointer",
        }}
      >
        {machineOperatorInitials ? (
          machineOperatorInitialsBlock
        ) : (
          <IconButton disabled={disableMachineOperatorSelection}>
            <PencilIcon />
          </IconButton>
        )}
      </TableCell>
    );
    let estimatedTimeCell;
    if (customerSettings.enableTaskEstimation) {
      estimatedTimeCell = (
        <TableCell
          key="estimatedtime"
          onClick={this.handleMinutesExpectedTotalTaskDurationEditClick}
          ref={this.estimatedTimeButtonRef}
          style={{
            ...tableRowColumnStyle,
            cursor: validatedAndRecorded || reportApproved ? "not-allowed" : "pointer",
          }}
        >
          {task.minutesExpectedTotalTaskDuration != null ? (
            formatDuration(customerSettings.durationFormat, task.minutesExpectedTotalTaskDuration)
          ) : (
            <IconButton disabled={validatedAndRecorded || reportApproved}>
              <PencilIcon />
            </IconButton>
          )}
        </TableCell>
      );
    }
    const disabled =
      !customerSettings.allowCustomerTaskMachineChange ||
      !allowMachineUse ||
      (workType?.allowMaxOneMachine && machineList.length > 0) ||
      validatedAndRecorded ||
      reportApproved;
    const cellOptionalClickHandlerProps: TableCellProps = {};
    if (!disabled) {
      cellOptionalClickHandlerProps.onClick = this.handleMachinesAddClick;
    }
    const machinesCell = (
      <TableCell
        key="machines"
        ref={this.machineButtonRef}
        style={{
          ...tableRowColumnStyle,
          cursor: disabled ? "not-allowed" : "pointer",
        }}
        {...cellOptionalClickHandlerProps}
      >
        {machines ? (
          machines
        ) : (
          <IconButton disabled={disabled}>
            <PencilIcon />
          </IconButton>
        )}
      </TableCell>
    );
    const dateCell = (
      <TableCell key="date" style={tableRowColumnStyle}>
        <DateField
          autoOk
          disabled={validatedAndRecorded || reportApproved}
          margin="dense"
          name="date"
          onChange={this.handleDateChange}
          placeholder="d/m/yy"
          style={{width: 170}}
          value={date || null}
        />
      </TableCell>
    );
    let timeCell: React.JSX.Element | undefined;
    if (customerSettings.showTimeColumnOnOrderEntryTaskList) {
      timeCell = (
        <TableCell key="time" style={tableRowColumnStyle}>
          <div>
            <TimeField
              disabled={validatedAndRecorded || reportApproved}
              margin="dense"
              name="time"
              onChange={this.handleTimeChange}
              placeholder="tt:mm"
              style={{width: 130}}
              value={task.time || undefined}
            />
          </div>
        </TableCell>
      );
    }
    let refNumberCell: React.JSX.Element | undefined;
    if (customerSettings.showTaskRefColumnOnOrderEntryTaskList) {
      refNumberCell = (
        <TableCell key="refnumber" style={tableRowColumnStyle}>
          {task.referenceNumber}
        </TableCell>
      );
    }
    let invoiceNoteCell: React.JSX.Element | undefined;
    if (customerSettings.showTaskInvoiceNoteColumnOnOrderEntryTaskList) {
      invoiceNoteCell = (
        <TableCell key="invoiceNote" style={tableRowColumnStyle}>
          {task.invoiceNote}
        </TableCell>
      );
    }
    let fieldsCell: React.JSX.Element | undefined;
    if (customerSettings.showFieldsColumnOnOrderEntryTaskList) {
      fieldsCell = (
        <TableCell key="fieldnumber" style={tableRowColumnStyle}>
          {task.fielduseSet
            .map((fieldUse) =>
              fieldUse.relatedField
                ? this.props.locationLookup(fieldUse.relatedField)?.fieldNumber
                : undefined,
            )
            .filter(Boolean)
            .join(", ")}
        </TableCell>
      );
    }
    let statusCell: React.JSX.Element | undefined;
    if (isManager) {
      statusCell = (
        <TableCell
          key="status"
          style={{
            ...tableRowColumnStyle,
            paddingLeft: 12,
            paddingRight: 12,
            width: 48,
          }}
        >
          <TaskStatusIcon task={task} timerStartArray={this.props.timerStartArray} />
        </TableCell>
      );
    }
    const detailsCell = (
      <TableCell
        key="details"
        style={{
          ...tableRowColumnStyle,
          paddingLeft: 0,
          paddingRight: 0,
          width: 48,
        }}
      >
        <IconButton onClick={this.handleGoToTaskClick}>
          <ClipboardTextIcon />
        </IconButton>
      </TableCell>
    );
    let taskCopyCell: React.JSX.Element | undefined;
    if (customerSettings.adminCanCreateCustomerTask) {
      const taskCopyButtonDisabled = order.validatedAndRecorded;
      taskCopyCell = (
        <TableCell
          key="copy"
          style={{
            ...tableRowColumnStyle,
            cursor: taskCopyButtonDisabled ? "not-allowed" : "pointer",
            paddingLeft: 0,
            paddingRight: 0,
            width: 48,
          }}
        >
          <IconButton disabled={taskCopyButtonDisabled} onClick={this.handleCopyClick}>
            <ContentCopyIcon />
          </IconButton>
        </TableCell>
      );
    }
    let taskMoveCell: React.JSX.Element | undefined;
    if (customerSettings.adminCanCreateCustomerTask) {
      taskMoveCell = (
        <TableCell
          key="MoveTask"
          style={{
            ...tableRowColumnStyle,
            paddingLeft: 0,
            paddingRight: 0,
            width: 48,
          }}
        >
          <MoveTask customerURL={this.props.customerURL} orderURL={order.url} taskURL={task.url} />
        </TableCell>
      );
    }
    const goToTaskCell = (
      <TableCell
        key="go-to-task"
        style={{
          ...tableRowColumnStyle,
          paddingLeft: 0,
          paddingRight: 0,
          width: 48,
        }}
      >
        <IconButton onClick={this.handleGoToTaskInstanceClick}>
          <TimerIcon />
        </IconButton>
      </TableCell>
    );
    let workTypeCell: React.JSX.Element;
    if (customerSettings.enableExternalTaskWorkTypeAndVariantSwitch) {
      workTypeCell = (
        <TableCell
          key="workType"
          onClick={this.handleWorkTypeSelectClick}
          ref={this.workTypeButtonRef}
          style={{
            ...tableRowColumnStyle,
            cursor: validatedAndRecorded || reportApproved ? "not-allowed" : "pointer",
          }}
        >
          {workType ? (
            workTypeString
          ) : (
            <IconButton disabled={validatedAndRecorded || reportApproved}>
              <PencilIcon />
            </IconButton>
          )}
        </TableCell>
      );
      dialogs.push(
        <ConnectedLogLegalExternalWorkTypeDialog
          customerUrl={order.customer}
          key="work-type-dialog"
          onCancel={this.handleWorkTypeDialogCancel}
          onOk={this.handleWorkTypeDialogOk}
          open={this.state.workTypeDialogOpen}
          task={task}
        />,
      );
    } else {
      workTypeCell = (
        <TableCell key="workType" style={tableRowColumnStyle}>
          {workTypeString}
        </TableCell>
      );
    }
    let columns: React.JSX.Element[];
    if (noExternalTaskWorkType) {
      columns = [
        machinesCell,
        priceGroupCell,
        workplaceCell,
        pickupDeliveryCells,
        machineOperatorCell,
        projectCell,
        refNumberCell,
        invoiceNoteCell,
        fieldsCell,
        estimatedTimeCell,
        dateCell,
        timeCell,
        statusCell,
        goToTaskCell,
        taskCopyCell,
        taskMoveCell,
        detailsCell,
      ].filter(notUndefined);
    } else {
      // has task worktype, does not have order worktype
      columns = [
        workTypeCell,
        priceGroupCell,
        workplaceCell,
        pickupDeliveryCells,
        machineOperatorCell,
        projectCell,
        refNumberCell,
        invoiceNoteCell,
        fieldsCell,
        estimatedTimeCell,
        machinesCell,
        dateCell,
        timeCell,
        statusCell,
        goToTaskCell,
        taskCopyCell,
        taskMoveCell,
        detailsCell,
      ].filter(notUndefined);
    }
    return (
      <TableRow>
        {columns}
        {dialogs}
      </TableRow>
    );
  }
  shouldComponentUpdate(
    nextProps: TaskEntryProps,
    nextState: TaskEntryState,
    _nextContext: unknown,
  ): boolean {
    for (let i = 0; i < taskEntryPropKeysExceptStuff.length; i += 1) {
      const key = taskEntryPropKeysExceptStuff[i];
      if (this.props[key] !== nextProps[key]) {
        return true;
      }
    }
    const result =
      !_.isEqual(this.props.task, nextProps.task) ||
      !_.isEqual(this.props.machineList, nextProps.machineList) ||
      !_.isEqual(this.props.machinePriceGroups, nextProps.machinePriceGroups) ||
      !_.isEqual(this.state, nextState);
    return result;
  }
}

export default TaskEntry;
