import {CustomerUrl, FieldUse, Location, LocationUrl} from "@co-common-libs/resources";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {Button, Card, CardContent, CardHeader, IconButton} from "@material-ui/core";
import CheckIcon from "mdi-react/CheckIcon";
import PencilIcon from "mdi-react/PencilIcon";
import React, {useCallback, useState} from "react";
import {FormattedMessage, FormattedNumber, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {FieldsNotesReordering, FieldUseWithField} from "../fields-notes-reordering";
import {FieldsMap} from "./fields-map";
import {FieldsNotes} from "./fields-notes";

interface FieldsCardProps {
  changeDisabled?: boolean;
  customerActiveFieldList: readonly Location[];
  customerURL: CustomerUrl | null;
  extraLabels?: ReadonlyMap<string, string>;
  extraNotes?: ReadonlyMap<string, string>;
  fieldUseList: readonly FieldUse[];
  isDefaultEditable?: boolean;
  locationLookup: (url: LocationUrl) => Location | undefined;
  onNotesChange: (fieldUse: FieldUseWithField) => void;
  onRearrange: (sourceField: FieldUseWithField, targetField: FieldUseWithField) => void;
  onSelectFieldsClick: () => void;
  withMap?: boolean;
}

export const FieldsCard = React.memo(function FieldsCard(
  props: FieldsCardProps,
): React.JSX.Element | null {
  const {
    changeDisabled,
    customerActiveFieldList,
    customerURL,
    extraLabels,
    fieldUseList,
    locationLookup,
    onSelectFieldsClick,
    withMap,
  } = props;

  const intl = useIntl();

  const customerSettings = useSelector(getCustomerSettings);

  const [editable, setEditable] = useState(false);
  const handleEditToogle = useCallback(() => {
    setEditable(!editable);
  }, [editable]);

  if (!customerActiveFieldList.length && !fieldUseList.length) {
    return null;
  }
  const fieldWithNotesList = fieldUseList
    .map((fieldUse) => ({
      ...fieldUse,
      field: locationLookup(fieldUse.relatedField),
    }))
    .filter((f) => f.field) as FieldUseWithField[];
  const totalSelectedArea =
    fieldWithNotesList
      .map((fieldUse) => fieldUse.field.fieldAreaHa || 0)
      .reduce((prev, current) => prev + current, 0) || 0;
  let mapBlock;
  if (withMap !== false && !!fieldUseList.length) {
    mapBlock = (
      <FieldsMap customerURL={customerURL} extraLabels={extraLabels} fieldUseList={fieldUseList} />
    );
  }

  let editIconButton;
  if (props.isDefaultEditable !== false && !!fieldUseList.length) {
    editIconButton = (
      <IconButton onClick={handleEditToogle} style={{float: "right"}}>
        {editable ? <CheckIcon color="#000" /> : <PencilIcon color="#000" />}
      </IconButton>
    );
  }
  return (
    <Card>
      <CardHeader title={intl.formatMessage({defaultMessage: "Marker"})} />
      {mapBlock}
      <CardContent>
        {totalSelectedArea > 0 ? (
          <div style={{float: "left"}}>
            <FormattedMessage defaultMessage="Total areal" tagName="span" />
            <span>
              :{" "}
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={totalSelectedArea}
              />{" "}
              ha
            </span>
          </div>
        ) : null}
        {editIconButton}
        <div style={{clear: "both"}} />
        {props.isDefaultEditable || editable ? (
          <FieldsNotesReordering
            extraNotes={props.extraNotes}
            fieldWithNotesList={fieldWithNotesList}
            onDrop={props.onRearrange}
            onNotesChange={props.onNotesChange}
            ordered
          />
        ) : (
          <FieldsNotes
            extraNotes={props.extraNotes}
            fieldWithNotesList={fieldWithNotesList}
            ordered
            showAll
          />
        )}
        <Button
          color="primary"
          disabled={
            !!changeDisabled ||
            (!customerActiveFieldList.length &&
              !customerSettings.addEditLogLocationSkipsCustomerSelection)
          }
          onClick={onSelectFieldsClick}
          variant="contained"
        >
          <FormattedMessage defaultMessage="Vælg marker" />
        </Button>
      </CardContent>
    </Card>
  );
});
