import {Config} from "@co-common-libs/config";
import {
  Customer,
  CustomerUrl,
  Location,
  LocationUrl,
  Machine,
  MachineUrl,
  MachineUse,
  Order,
  OrderUrl,
  PatchUnion,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  PriceItemUse,
  PriceItemUsesDict,
  Product,
  ProductUrl,
  ProductUse,
  ProductUsesDict,
  Project,
  ProjectUrl,
  Task,
  TaskUrl,
  Timer,
  TimerStart,
  TimerUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {
  formatDuration,
  getNormalisedDeviceTimestamp,
  timerMayBeBilled,
} from "@co-common-libs/resources-utils";
import {mapFilter, MINUTE_MILLISECONDS, sortByOrderMember} from "@co-common-libs/utils";
import {
  actions,
  AppState,
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
  getMachineLookup,
  getOrderLookup,
  getPriceGroupLookup,
  getPriceItemLookup,
  getProductLookup,
  getProjectArray,
  getProjectLookup,
  getTimerArray,
  getTimerLookup,
  getTimerStartArray,
  getWorkTypeLookup,
  PathTemplate,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import {InvoiceLineTable, RouteInvoiceLineTable} from "app-components";
import {
  computeIntervalsTruncated,
  computeIntervalSums,
  getBreakTimer,
  getGenericPrimaryTimer,
  mapSum,
  mergeIntervals,
  PureComponent,
} from "app-utils";
import {bind} from "bind-decorator";
import bowser from "bowser";
import _ from "lodash";
import React from "react";
import {FormattedMessage, IntlContext} from "react-intl";
import {connect, useSelector} from "react-redux";
import {createStructuredSelector} from "reselect";
import {generateVariableBookeepingInfoHeader} from "../../bookkeeping/utils";
import {
  MINUTES_HEADER_STYLE,
  MINUTES_STYLE,
  orderIconLinkColumnStyle,
  photoIconColumnStyle,
  statusIconColumnStyle,
  TRUNCATE_STYLE,
} from "./style";
import {TaskRow} from "./task-row";

const MINIMUM_COLUMN_COUNT = 9;
const COLUMN_SPAN = 4;

export function computeTasksIntervals(
  sortedTasks: readonly Task[],
  timerStartArray: readonly TimerStart[],
): ReadonlyMap<
  TaskUrl,
  readonly {
    readonly fromTimestamp: string;
    readonly timer: TimerUrl | null;
    readonly toTimestamp: string;
  }[]
> {
  const computeIntervalsForTaskURLSet = new Set(
    sortedTasks.filter((t) => !t.archivable).map((t) => t.url),
  );
  const taskIntervals = new Map<
    TaskUrl,
    readonly {
      readonly fromTimestamp: string;
      readonly timer: TimerUrl | null;
      readonly toTimestamp: string;
    }[]
  >();
  const timerStartList = timerStartArray.filter((instance) =>
    computeIntervalsForTaskURLSet.has(instance.task),
  );
  sortedTasks.forEach((task) => {
    const taskURL = task.url;
    let computedIntervals;
    if (task.archivable) {
      computedIntervals = task.computedTimeSet;
    } else {
      const timerStarts = _.sortBy(
        timerStartList.filter((instance) => instance.task === taskURL),
        getNormalisedDeviceTimestamp,
      );
      computedIntervals = computeIntervalsTruncated(timerStarts);
    }
    const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
    const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
    const intervals = mergeIntervals(
      computedIntervals,
      correctionIntervals,
      managerCorrectionIntervals,
    );
    taskIntervals.set(taskURL, intervals);
  });

  return taskIntervals;
}

export function computeTaskHasIntervalOverlap(
  taskIntervals: ReadonlyMap<
    TaskUrl,
    readonly {
      readonly fromTimestamp: string;
      readonly timer: TimerUrl | null;
      readonly toTimestamp: string;
    }[]
  >,
  customerSettings: Config,
  now: Date,
): ReadonlyMap<TaskUrl, boolean> {
  const taskHasIntervalOverlap = new Map<TaskUrl, boolean>();

  const {taskOverlapWarningAfterMinutes} = customerSettings;
  const taskOverlapWarningAfterMilliseconds = taskOverlapWarningAfterMinutes * MINUTE_MILLISECONDS;

  taskIntervals.forEach((intervals, taskURL) => {
    taskHasIntervalOverlap.set(taskURL, false);
    intervals.forEach((interval) => {
      const fromTimestamp = new Date(interval.fromTimestamp);
      const toTimestamp = new Date(interval.toTimestamp || now);
      taskIntervals.forEach((otherTaskIntervals, otherTaskURL) => {
        if (otherTaskURL !== taskURL) {
          otherTaskIntervals.forEach((otherInterval) => {
            const otherFromTimestamp = new Date(otherInterval.fromTimestamp);
            const otherToTimestamp = new Date(otherInterval.toTimestamp || now);
            if (otherFromTimestamp >= toTimestamp) {
              return;
            }
            if (otherToTimestamp <= fromTimestamp) {
              return;
            }
            const overlapFrom = Math.max(fromTimestamp.valueOf(), otherFromTimestamp.valueOf());
            const overlapTo = Math.min(toTimestamp.valueOf(), otherToTimestamp.valueOf());
            if (overlapTo - overlapFrom >= taskOverlapWarningAfterMilliseconds) {
              taskHasIntervalOverlap.set(taskURL, true);
            }
          });
        }
      });
    });
  });

  return taskHasIntervalOverlap;
}

export const computeRowData = ({
  customer,
  intervals,
  locationLookup,
  machineLookup,
  now,
  priceGroupLookup,
  priceItemLookup,
  productLookup,
  projectArray,
  projectLookup,
  task,
  timerArray,
  timerLookup,
  workTypeLookup,
}: {
  customer?: Customer | undefined;
  intervals: readonly {
    readonly fromTimestamp: string;
    readonly timer: Timer | TimerUrl | null;
    readonly toTimestamp: string;
  }[];
  locationLookup: (url: LocationUrl) => Location | undefined;
  machineLookup: (url: MachineUrl) => Machine | undefined;
  now: Date;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  productLookup: (url: ProductUrl) => Product | undefined;
  projectArray: readonly Project[];
  projectLookup: (url: ProjectUrl) => Project | undefined;
  task: Task;
  timerArray: readonly Timer[];
  timerLookup: (url: TimerUrl) => Timer | undefined;
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}): {
  customerHasActiveProjects: boolean;
  department: string;
  externalPrimaryMinutes: number;
  machineUseList: (Omit<MachineUse, "machine"> & {
    machine: Machine | undefined;
  })[];
  priceItemUseList: (Omit<PriceItemUse, "priceItem"> & {
    priceItem: PriceItem | undefined;
  })[];
  productUseList: (Omit<ProductUse, "product"> & {
    product: Product | undefined;
  })[];
  project: Project | undefined;
  totalRegistered: number;
  workplace: Location | undefined;
} => {
  const priceItemUseList = sortByOrderMember(Object.values(task.priceItemUses || {})).map(
    (entry) => {
      const priceItemURL = entry.priceItem;
      const priceItem = priceItemLookup(priceItemURL);
      return {...entry, priceItem};
    },
  );
  const productUseList = sortByOrderMember(Object.values(task.productUses || {})).map((entry) => {
    const productURL = entry.product;
    const product = productLookup(productURL);
    return {...entry, product};
  });

  const machineUseList = (task.machineuseSet || []).map((machineUse) => {
    const machineURL = machineUse.machine;
    return {...machineUse, machine: machineLookup(machineURL)};
  });
  const timerMinutes = computeIntervalSums(intervals, now);

  const isInternal = !task.order;

  const breakTimer = getBreakTimer(timerArray);
  const breakTimerURL = (breakTimer && breakTimer.url) || "";
  let externalPrimaryMinutes: number;
  if (isInternal) {
    externalPrimaryMinutes = 0;
  } else {
    const primaryTimer = getGenericPrimaryTimer(timerArray);
    const primaryTimerURL = (primaryTimer && primaryTimer.url) || "";
    externalPrimaryMinutes = mapSum(
      mapFilter(timerMinutes, (_val, key) =>
        timerMayBeBilled(
          key,
          primaryTimerURL as TimerUrl,
          timerLookup,
          workTypeLookup,
          priceGroupLookup,
          priceItemLookup,
        ),
      ),
    );
  }
  const z = new Map(timerMinutes);
  if (breakTimerURL) {
    z.delete(breakTimerURL);
  }
  const totalRegistered = mapSum(z);
  const workplaceURL = task.relatedWorkplace;
  const workplace = workplaceURL ? locationLookup(workplaceURL) : undefined;
  const projectURL = task.project;
  const project = projectURL ? projectLookup(projectURL) : undefined;
  let customerHasActiveProjects = false;
  if (customer) {
    customerHasActiveProjects = projectArray.some(
      (entry) => entry.customer === customer.url && entry.access === "open",
    );
  }
  return {
    customerHasActiveProjects,
    department: task.department,
    externalPrimaryMinutes,
    machineUseList,
    priceItemUseList,
    productUseList,
    project,
    totalRegistered,
    workplace,
  };
};

interface OuterTaskRowProps {
  customer?: Customer | undefined;
  hasOverlap: boolean;
  hasPhoto: boolean;
  intervals: readonly {
    readonly fromTimestamp: string;
    readonly timer: Timer | TimerUrl | null;
    readonly toTimestamp: string;
  }[];
  isManager: boolean;
  nextTask?: Task | undefined;
  now: Date;
  order?: Order | undefined;
  previousTask?: Task | undefined;
  showDepartment: boolean;
  task: Task;
  workType?: WorkType | undefined;
}

const OuterTaskRow = React.memo(function OuterTaskRow(props: OuterTaskRowProps): React.JSX.Element {
  const {
    customer,
    hasOverlap,
    hasPhoto,
    intervals,
    isManager,
    nextTask,
    now,
    order,
    previousTask,
    showDepartment,
    task,
    workType,
  } = props;
  const locationLookup = useSelector(getLocationLookup);
  const machineLookup = useSelector(getMachineLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const productLookup = useSelector(getProductLookup);
  const projectArray = useSelector(getProjectArray);
  const projectLookup = useSelector(getProjectLookup);
  const timerArray = useSelector(getTimerArray);
  const timerLookup = useSelector(getTimerLookup);
  const workTypeLookup = useSelector(getWorkTypeLookup);

  const {
    customerHasActiveProjects,
    department,
    externalPrimaryMinutes,
    machineUseList,
    priceItemUseList,
    productUseList,
    project,
    totalRegistered,
  } = computeRowData({
    customer,
    intervals,
    locationLookup,
    machineLookup,
    now,
    priceGroupLookup,
    priceItemLookup,
    productLookup,
    projectArray,
    projectLookup,
    task,
    timerArray,
    timerLookup,
    workTypeLookup,
  });
  return (
    <TaskRow
      customer={customer}
      customerHasActiveProjects={customerHasActiveProjects}
      department={department}
      externalPrimaryMinutes={externalPrimaryMinutes}
      hasOverlap={hasOverlap}
      hasPhoto={hasPhoto}
      isManager={isManager}
      machineUseList={machineUseList}
      nextTask={nextTask}
      order={order}
      previousTask={previousTask}
      priceItemUseList={priceItemUseList}
      productUseList={productUseList}
      project={project}
      showDepartment={showDepartment}
      task={task}
      totalRegistered={totalRegistered}
      workType={workType}
    />
  );
});

interface SecondaryTimerRowProps {
  customerSettings: Config;
  isManager: boolean;
  minutes: number;
  timer: Timer;
  validatedAndRecorded: boolean;
}

class SecondaryTimerRow extends PureComponent<SecondaryTimerRowProps> {
  render(): React.JSX.Element {
    const {isManager, minutes, timer, validatedAndRecorded} = this.props;
    const rowStyle: React.CSSProperties = validatedAndRecorded ? {color: grey[500]} : {};
    const {customerSettings} = this.props;
    let orderIconLinkColumn;
    let statusIconColumn;
    if (isManager) {
      orderIconLinkColumn = <TableCell style={orderIconLinkColumnStyle} />;
      statusIconColumn = <TableCell style={statusIconColumnStyle} />;
    }
    const photoIconColumn = <TableCell style={photoIconColumnStyle} />;
    const iconWidth = 24;
    const customerColumnPaddingLeft = orderIconLinkColumn ? 0 : iconWidth;
    const startStyle = MINUTES_STYLE;
    const endStyle = MINUTES_STYLE;
    const timerLabel = timer.label;
    return (
      <TableRow style={rowStyle}>
        <TableCell
          style={{
            whiteSpace: "normal",

            width: 102 + (customerSettings.bookkeepingMachineNames ? 104 : 0),
          }}
        />
        <TableCell style={{width: 206}}>{timerLabel}</TableCell>
        {orderIconLinkColumn}
        <TableCell
          style={{
            paddingLeft: customerColumnPaddingLeft,
            paddingRight: 0,
            width: 206,
          }}
        />
        <TableCell style={startStyle} />
        <TableCell style={endStyle} />
        <TableCell style={MINUTES_STYLE}>
          {formatDuration(customerSettings.durationFormat, minutes)}
        </TableCell>
        <TableCell style={MINUTES_STYLE} />
        <TableCell />
        {statusIconColumn}
        {photoIconColumn}
      </TableRow>
    );
  }
}

interface BookkeepingTaskTableStateProps {
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  locationLookup: (url: LocationUrl) => Location | undefined;
  orderLookup: (url: OrderUrl) => Order | undefined;
  projectLookup: (url: ProjectUrl) => Project | undefined;
  timerArray: readonly Timer[];
  timerLookup: (url: TimerUrl) => Timer | undefined;
  timerStartArray: readonly TimerStart[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface BookkeepingTaskTableDispatchProps {
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface BookkeepingTaskTableOwnProps {
  isManager: boolean;
  tasks: readonly Task[];
  tasksWithPhotos: ReadonlySet<string>;
}

type BookkeepingTaskTableProps = BookkeepingTaskTableDispatchProps &
  BookkeepingTaskTableOwnProps &
  BookkeepingTaskTableStateProps;

class BookkeepingTaskTable extends PureComponent<BookkeepingTaskTableProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  // eslint-disable-next-line class-methods-use-this
  @bind
  handleInvoiceLineClick(event: React.SyntheticEvent): void {
    event.stopPropagation();
  }
  render(): React.JSX.Element {
    const {customerSettings} = this.props;
    const sortedTasks = _.sortBy(this.props.tasks, (task) => task.workFromTimestamp);
    const now = new Date();
    now.setUTCMilliseconds(0);

    const taskIntervals = computeTasksIntervals(sortedTasks, this.props.timerStartArray);
    const taskHasIntervalOverlap = computeTaskHasIntervalOverlap(
      taskIntervals,
      customerSettings,
      now,
    );

    const deviceType = bowser.mobile ? "mobile" : bowser.tablet ? "tablet" : "desktop";
    const showDepartment =
      customerSettings.bookkeepingListColumns[deviceType].includes("departments");

    const noRowsMessage = (
      <div style={{padding: 16}}>
        <FormattedMessage
          defaultMessage="Der er ingen fuldførte opgaver tilhørende denne dag"
          id="task-table.no-entries"
          tagName="h4"
        />
      </div>
    );
    let orderIconLinkColumn;
    let statusIconColumn;
    if (this.props.isManager) {
      orderIconLinkColumn = <TableCell style={orderIconLinkColumnStyle} />;
      statusIconColumn = <TableCell style={statusIconColumnStyle} />;
    }
    let orderReferenceNumberColumn: React.JSX.Element | undefined;
    if (customerSettings.enableOrderReferenceNumber) {
      const labelFromSettings = customerSettings.orderReferenceNumberLabel;
      orderReferenceNumberColumn = (
        <TableCell style={{...TRUNCATE_STYLE, width: 200}}>
          {labelFromSettings ? (
            labelFromSettings
          ) : (
            <FormattedMessage defaultMessage="Referencenr." />
          )}
        </TableCell>
      );
    }
    let taskReferenceNumberColumn: React.JSX.Element | undefined;
    if (customerSettings.enableTaskReferenceNumber) {
      const labelFromSettings = customerSettings.taskReferenceNumberLabel;
      taskReferenceNumberColumn = (
        <TableCell style={{...TRUNCATE_STYLE, width: 200}}>
          {labelFromSettings ? (
            labelFromSettings
          ) : (
            <FormattedMessage defaultMessage="Referencenr." />
          )}
        </TableCell>
      );
    }

    const columnCount =
      MINIMUM_COLUMN_COUNT +
      (orderReferenceNumberColumn ? 1 : 0) +
      (taskReferenceNumberColumn ? 1 : 0) +
      (showDepartment ? 1 : 0) +
      (orderIconLinkColumn ? 1 : 0) +
      (statusIconColumn ? 1 : 0) +
      (customerSettings.showCalledInDialogAfterMinutes ? 1 : 0);

    const rows: React.JSX.Element[] = [];
    sortedTasks.forEach((task, index) => {
      const taskURL = task.url;
      const orderURL = task.order;
      const order = typeof orderURL === "string" ? this.props.orderLookup(orderURL) : orderURL;
      const customerURL = order ? order.customer : null;
      const customer =
        typeof customerURL === "string" ? this.props.customerLookup(customerURL) : customerURL;
      const workTypeURL = task.workType;
      const workType =
        typeof workTypeURL === "string" ? this.props.workTypeLookup(workTypeURL) : workTypeURL;
      const intervals = taskIntervals.get(taskURL) || [];
      const hasOverlap = taskHasIntervalOverlap.get(taskURL);

      const hasPhoto = this.props.tasksWithPhotos.has(taskURL);

      rows.push(
        <OuterTaskRow
          customer={customer || undefined}
          hasOverlap={hasOverlap || false}
          hasPhoto={hasPhoto}
          intervals={intervals}
          isManager={this.props.isManager}
          key={taskURL}
          nextTask={sortedTasks[index + 1]}
          now={now}
          order={order || undefined}
          previousTask={index ? sortedTasks[index - 1] : undefined}
          showDepartment={showDepartment}
          task={task}
          workType={workType || undefined}
        />,
      );
      if (customerSettings.bookkeepingDayLinePerTimer && task.order) {
        // NOTE: computeRowData includes other/"extra" timers as part of
        // effective time; those in externalSecondaryTimers are specifically
        // not included.
        const {timerArray, timerLookup} = this.props;
        const breakTimer = getBreakTimer(timerArray);
        const primaryTimer = getGenericPrimaryTimer(timerArray);
        const timerMinutes = computeIntervalSums(intervals, now);
        timerMinutes.forEach((minutes, timerURL) => {
          if (minutes && timerURL !== breakTimer?.url && timerURL !== primaryTimer?.url) {
            const timer = timerLookup(timerURL);
            if (timer) {
              rows.push(
                <SecondaryTimerRow
                  customerSettings={customerSettings}
                  isManager={this.props.isManager}
                  key={`${taskURL}-${timerURL}`}
                  minutes={minutes}
                  timer={timer}
                  validatedAndRecorded={!!task.validatedAndRecorded}
                />,
              );
            }
          }
        });
      }
      if (customerSettings.enableInvoiceCorrections && order && task.completed) {
        const editDisabled = task.validatedAndRecorded;

        rows.push(
          <TableRow key={`${task.url}-values`} onClick={this.handleInvoiceLineClick}>
            <TableCell colSpan={COLUMN_SPAN} />
            <TableCell
              colSpan={columnCount - COLUMN_SPAN}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              {order.routePlan ? (
                <RouteInvoiceLineTable readonly={!!editDisabled} task={task} />
              ) : (
                <InvoiceLineTable
                  editDisabled={!!editDisabled}
                  striped={false}
                  task={
                    task as Task & {
                      readonly priceItemUses: PriceItemUsesDict;
                      readonly productUses: ProductUsesDict;
                    }
                  }
                />
              )}
            </TableCell>
          </TableRow>,
        );
      }
    });

    const customerProjectWorkplaceHeaderText = generateVariableBookeepingInfoHeader(
      this.context.formatMessage,
      customerSettings,
    );

    const customerColumnPaddingLeft = orderIconLinkColumn ? 0 : 24;
    const table = (
      <Table>
        <TableHead
          style={{
            borderColor: "rgb(224, 224, 224)",
            borderStyle: "solid",
            borderWidth: "1px 0px 0px 0px",
          }}
        >
          <TableRow>
            <TableCell
              style={{
                width: 102 + (customerSettings.bookkeepingMachineNames ? 104 : 0),
              }}
            >
              {customerSettings.machineLabelVariant === "MACHINE" ? (
                <FormattedMessage
                  defaultMessage="Maskiner"
                  id="bookkeeping-table.table-header.machines"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Køretøjer"
                  id="bookkeeping-table.table-header.vehicles"
                />
              )}
            </TableCell>

            <TableCell style={{...TRUNCATE_STYLE, width: 206}}>
              {customerSettings.bookkeepingWorkTypeAsWorkTypeAndVariant ? (
                <FormattedMessage
                  defaultMessage="Område (variant)"
                  id="bookkeeping-table.alternative-table-header.work-type"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Område"
                  id="bookkeeping-table.table-header.work-type"
                />
              )}
            </TableCell>
            {showDepartment ? (
              <TableCell
                style={{
                  width: 206,
                }}
              >
                <FormattedMessage defaultMessage="Afdeling" />
              </TableCell>
            ) : null}
            {orderIconLinkColumn}
            {orderReferenceNumberColumn}
            {taskReferenceNumberColumn}
            <TableCell
              style={{
                paddingLeft: customerColumnPaddingLeft,
                width: 206,
              }}
            >
              {customerProjectWorkplaceHeaderText}
            </TableCell>
            <TableCell style={MINUTES_HEADER_STYLE}>
              <FormattedMessage defaultMessage="Start" id="bookkeeping-table.table-header.start" />
            </TableCell>
            <TableCell style={MINUTES_HEADER_STYLE}>
              <FormattedMessage defaultMessage="Slut" id="bookkeeping-table.table-header.end" />
            </TableCell>
            <TableCell style={MINUTES_HEADER_STYLE}>
              <FormattedMessage defaultMessage="Tid" id="bookkeeping-table.table-header.time" />
            </TableCell>
            <TableCell style={MINUTES_HEADER_STYLE}>
              <FormattedMessage
                defaultMessage="Eff. tid"
                id="bookkeeping-table.table-header.effective-time"
              />
            </TableCell>
            <TableCell>
              {customerSettings.materialUseAlternativeText ? (
                <FormattedMessage
                  defaultMessage="Noter og materialer"
                  id="bookkeeping-table.alternative-table-header.notes-and-material"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Noter og materiel"
                  id="bookkeeping-table.table-header.notes-and-material"
                />
              )}
            </TableCell>
            {customerSettings.showCalledInDialogAfterMinutes ? (
              <TableCell style={{width: 70}}>
                <FormattedMessage defaultMessage="Tilkald" id="bookkeeping-table.called-in" />
              </TableCell>
            ) : null}
            {statusIconColumn}
            <TableCell style={photoIconColumnStyle} />
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    );
    return <div style={{minWidth: 1000}}>{rows.length ? table : noRowsMessage}</div>;
  }
}

const ConnectedBookkeepingTaskTable: React.ComponentType<BookkeepingTaskTableOwnProps> = connect<
  BookkeepingTaskTableStateProps,
  BookkeepingTaskTableDispatchProps,
  BookkeepingTaskTableOwnProps,
  AppState
>(
  createStructuredSelector<AppState, BookkeepingTaskTableStateProps>({
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    locationLookup: getLocationLookup,
    orderLookup: getOrderLookup,
    projectLookup: getProjectLookup,
    timerArray: getTimerArray,
    timerLookup: getTimerLookup,
    timerStartArray: getTimerStartArray,
    workTypeLookup: getWorkTypeLookup,
  }),
  {
    go: actions.go,
    update: actions.update,
  },
)(BookkeepingTaskTable);

export {ConnectedBookkeepingTaskTable as BookkeepingTaskTable};
