import type {Writable} from "ts-essentials";
import {
  CustomerUrl,
  emptyTask,
  FieldUse,
  Location,
  LocationType,
  LocationTypeUrl,
  LocationUrl,
  MachineUrl,
  MachineUse,
  Order,
  Patch,
  PriceGroupUrl,
  ProductUrl,
  ProjectUrl,
  Task,
  TaskUrl,
  urlToId,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {dateToString, formatAddress, notUndefined} from "@co-common-libs/utils";
import {ResponsiveDialog, SpinnerDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentRole,
  getCurrentUser,
  getCurrentUserProfile,
  getCurrentUserURL,
  getCustomerLookup,
  getCustomerSettings,
  getEmployeeGroupLookup,
  getLocationLookup,
} from "@co-frontend-libs/redux";
import {jsonFetch, useCallWithFalse} from "@co-frontend-libs/utils";
import {DialogContent} from "@material-ui/core";
import {useMachine} from "@xstate/react/lib/fsm";
import {computeDepartment, createOrder} from "app-utils";
import {globalConfig, instanceURL} from "frontend-global-config";
import React, {useCallback, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {LocationCreateEditDialog} from "../../location-create-edit-dialog";
import {CustomerTaskCreationDisplay} from "../../wizards/customer-task-creation/customer-task-creation-display";
import {customerTaskCreationStateMachine} from "../../wizards/customer-task-creation/customer-task-creation-state-machine";
import {LocationTypeMarkersWithMenu} from "./location-type-markers-with-menu";

async function changeLocationCustomer(locationURL: string, customerURL: string): Promise<void> {
  const {baseURL} = globalConfig.resources;
  const url = `${baseURL}location_change_customer/${urlToId(locationURL)}`;
  await jsonFetch(url, "POST", {
    locationURL,
    newCustomerId: urlToId(customerURL),
    newCustomerUrl: customerURL,
  });
}

interface LocationMarkersWithMenuProps {
  locationArray: readonly Location[];
  locationProductCounts?: ReadonlyMap<LocationUrl, ReadonlyMap<ProductUrl, number>>;
  locationTaskUrlsMap?: ReadonlyMap<LocationUrl, ReadonlySet<TaskUrl>>;
  map: google.maps.Map;
  selectedMarkerTypes: readonly string[];
  showAsActive?: boolean;
  usedLocationTypes: readonly LocationType[];
}

export function LocationMarkersWithMenu(props: LocationMarkersWithMenuProps): React.JSX.Element {
  const {
    locationArray,
    locationProductCounts,
    locationTaskUrlsMap,
    map,
    selectedMarkerTypes,
    showAsActive,
    usedLocationTypes,
  } = props;

  const customerSettings = useSelector(getCustomerSettings);
  const {customerTaskCreationWizard, seniorMachineOperatorCanManageOrders} = customerSettings;
  const currentRole = useSelector(getCurrentRole);
  const currentUserProfile = useSelector(getCurrentUserProfile);
  const currentUserURL = useSelector(getCurrentUserURL);
  const currentUser = useSelector(getCurrentUser);
  const employeeGroupLookup = useSelector(getEmployeeGroupLookup);
  const [locationDialogOpenedFor, setlocationDialogOpenedFor] = useState<Location | null>(null);

  const dispatch = useDispatch();

  const locationLookup = useSelector(getLocationLookup);
  const customerLookup = useSelector(getCustomerLookup);

  const handleCustomerTaskCreationWizardOk = useCallback(
    (data: {
      customer: CustomerUrl;
      department: string | null;
      fields: readonly LocationUrl[];
      machines: readonly {
        readonly machine: MachineUrl;
        readonly priceGroup: PriceGroupUrl | null;
      }[];
      priceGroup: PriceGroupUrl | null;
      project: ProjectUrl | null;
      workPlace: LocationUrl | null;
      workType: WorkTypeUrl | null;
    }): void => {
      const {customer, department, fields, machines, priceGroup, project, workPlace, workType} =
        data;

      if (!currentUserURL || !customer) {
        return;
      }

      const machineuseSet = machines.map(
        ({machine, priceGroup: machinePriceGroup}): MachineUse => ({
          machine,
          priceGroup: machinePriceGroup,
          transporter: false,
        }),
      );
      const fielduseSet = fields
        .map(locationLookup)
        .filter(notUndefined)
        .map(
          (field): FieldUse => ({
            fieldAreaHa: field.fieldAreaHa,
            fieldCrop: field.fieldCrop,
            geojson: field.geojson,
            notes: "",
            relatedField: field.url,
          }),
        );

      const userIsOnlyMachineOperator = currentRole && !currentRole.manager;
      console.assert(userIsOnlyMachineOperator);
      const date = dateToString(new Date());
      const workPlaceInstance = (workPlace && locationLookup(workPlace)) || undefined;
      const address = formatAddress(workPlaceInstance);
      const newOrder: Writable<Order> = createOrder({
        address,
        created: new Date().toISOString(),
        createdBy: currentUserURL,
        customer,
        date,
        durationDays: 1,
        relatedWorkplace: workPlace,
      });

      if (
        customerSettings.enableOrderReferenceNumber &&
        customerSettings.autoFillReferenceNumberWithCustomerAccount
      ) {
        const customerInstance = customerLookup(customer);
        const referenceNumber = (customerInstance && customerInstance.c5_account) || "";
        newOrder.referenceNumber = referenceNumber;
      }
      dispatch(actions.create(newOrder));

      const taskID = uuid();
      const taskURL = instanceURL("task", taskID);
      const newTask: Writable<Task> = {
        ...emptyTask,
        address,
        created: new Date().toISOString(), // for local ordering until set by server
        createdBy: currentUserURL,
        date,
        department: department || "",
        fielduseSet,
        id: taskID,
        machineOperator: currentUserURL,
        order: newOrder.url,
        priceGroup,
        priority: null,
        project,
        relatedWorkplace: workPlace,
        url: taskURL,
        workType,
      };
      if (machineuseSet) {
        newTask.machineuseSet = machineuseSet;
      }

      dispatch(actions.create(newTask));

      window.setTimeout(() => {
        dispatch(actions.go("/task/:id", {id: taskID}));
      }, 0);
    },
    [currentRole, currentUserURL, customerLookup, customerSettings, dispatch, locationLookup],
  );

  const [taskCreationState, taskCreationSend] = useMachine(customerTaskCreationStateMachine, {
    actions: {
      signalDone: (context, _event) => {
        const {customer, department, fields, location, machines, priceGroup, project, workType} =
          context;
        handleCustomerTaskCreationWizardOk({
          customer: customer as CustomerUrl,
          department,
          fields,
          machines,
          priceGroup,
          project,
          workPlace: location,
          workType,
        });
      },
    },
  });

  const handleCreateTask = useCallback(
    (location: Location) => {
      const userIsManager = currentRole && currentRole.manager;
      const userIsSeniorMachineOperator = currentRole && currentRole.seniorMachineOperator;

      if (customerTaskCreationWizard && !userIsManager && !userIsSeniorMachineOperator) {
        const employeeGroupURL = currentUserProfile && currentUserProfile.employeeGroup;

        const employeeGroup = employeeGroupURL && employeeGroupLookup(employeeGroupURL);
        const defaultDepartment =
          (currentUser &&
            computeDepartment(currentUserProfile, employeeGroup || null, customerSettings)) ||
          null;
        taskCreationSend({
          customer: location.customer,
          department: defaultDepartment,
          departmentsEnabled: customerSettings.enableExternalTaskDepartmentField,
          fields: [location.url],
          projectsEnabled: customerSettings.enableProjects,
          skipMachineChoice: false,
          type: "START",
          workTypesEnabled: !customerSettings.noExternalTaskWorkType,
        });
        return;
      }

      if (!currentUserURL || !location) {
        return;
      }

      const newOrder: Writable<Order> = createOrder({
        created: new Date().toISOString(),
        createdBy: currentUserURL,
        customer: location.customer,
      });
      const userIsOnlyMachineOperator = currentRole && !currentRole.manager;
      const today = dateToString(new Date());
      if (userIsOnlyMachineOperator) {
        newOrder.date = today;
      }
      dispatch(actions.create(newOrder));

      const employeeGroupURL = currentUserProfile && currentUserProfile.employeeGroup;
      const employeeGroup = employeeGroupURL && employeeGroupLookup(employeeGroupURL);

      const taskID = uuid();
      const taskURL = instanceURL("task", taskID);
      const newTask: Writable<Task> = {
        ...emptyTask,
        created: new Date().toISOString(), // for local ordering until set by server
        createdBy: currentUserURL,
        date: userIsOnlyMachineOperator ? today : null,
        department: currentUser
          ? computeDepartment(currentUserProfile, employeeGroup || null, customerSettings)
          : "",
        fielduseSet: [
          {
            fieldAreaHa: location.fieldAreaHa,
            fieldCrop: location.fieldCrop,
            geojson: location.geojson,
            notes: "",
            relatedField: location.url,
          },
        ],
        id: taskID,
        machineOperator: customerSettings.defaultTaskEmployee
          ? instanceURL("user", customerSettings.defaultTaskEmployee)
          : null,
        order: newOrder.url,

        priority: Math.pow(2, 28),
        url: taskURL,
      };

      if (
        userIsOnlyMachineOperator &&
        !(userIsSeniorMachineOperator && seniorMachineOperatorCanManageOrders)
      ) {
        newTask.machineOperator = currentUserURL;
      }
      dispatch(actions.create(newTask));

      window.setTimeout(() => {
        dispatch(actions.go("/order/:id", {id: urlToId(newOrder.url)}));
      }, 0);
    },
    [
      currentRole,
      currentUser,
      currentUserProfile,
      currentUserURL,
      customerSettings,
      customerTaskCreationWizard,
      dispatch,
      employeeGroupLookup,
      seniorMachineOperatorCanManageOrders,
      taskCreationSend,
    ],
  );

  const [spinnerMoveDialogOpen, setSpinnerMoveDialogOpen] = useState(false);
  const [errorMoveDialogOpen, setErrorMoveDialogOpen] = useState(false);
  const setErrorMoveDialogOpenFalse = useCallWithFalse(setErrorMoveDialogOpen);

  const handleExtendedLocationCreateEditDialogOk = useCallback(
    ({
      active,
      address,
      attention,
      city,
      coordinatesFromAddress,
      customer,
      customerChanged,
      favorite,
      latitude,
      locationType,
      logOnlyLocation,
      longitude,
      name,
      phone,
      postalCode,
      workplaceOnlyLocation,
    }: {
      active: boolean;
      address: string;
      attention: string;
      city: string;
      coordinatesFromAddress: boolean;
      customer: CustomerUrl | null;
      customerChanged?: boolean;
      favorite: boolean;
      latitude: number | null;
      locationType: LocationTypeUrl | null;
      logOnlyLocation: boolean;
      longitude: number | null;
      name: string;
      phone: string;
      postalCode: string;
      workplaceOnlyLocation: boolean;
    }): void => {
      if (!locationDialogOpenedFor) {
        return;
      }

      const patch: Patch<Location> = [
        {member: "active", value: active},
        {member: "address", value: address},
        {member: "attention", value: attention},
        {member: "city", value: city},
        {member: "customer", value: customer},
        {member: "favorite", value: favorite},
        {member: "latitude", value: latitude},
        {member: "locationType", value: locationType},
        {member: "logOnlyLocation", value: logOnlyLocation},
        {member: "longitude", value: longitude},
        {member: "name", value: name},
        {member: "phone", value: phone},
        {member: "postalCode", value: postalCode},
        {member: "workplaceOnlyLocation", value: workplaceOnlyLocation},
        {member: "coordinatesFromAddress", value: coordinatesFromAddress},
      ];
      dispatch(actions.update(locationDialogOpenedFor.url, patch));
      if (customerChanged && customer) {
        setSpinnerMoveDialogOpen(true);
        changeLocationCustomer(locationDialogOpenedFor.url, customer)
          .then(() => {
            setSpinnerMoveDialogOpen(false);
            return;
          })
          .catch(() => {
            setSpinnerMoveDialogOpen(false);
            setErrorMoveDialogOpen(true);
          });
      }

      setlocationDialogOpenedFor(null);
    },
    [dispatch, locationDialogOpenedFor],
  );

  const handleLocationCreateEditDialogCancel = useCallback(() => {
    setlocationDialogOpenedFor(null);
  }, []);

  const intl = useIntl();
  return (
    <>
      {usedLocationTypes
        .filter((locationType) => selectedMarkerTypes.includes(locationType.identifier))
        .map((locationType) => (
          <LocationTypeMarkersWithMenu
            filteredLocationArray={locationArray}
            key={locationType.url}
            locationProductCounts={locationProductCounts}
            locationTaskUrlsMap={locationTaskUrlsMap}
            locationType={locationType}
            map={map}
            onRequestCreateTask={handleCreateTask}
            onRequestEditLocation={setlocationDialogOpenedFor}
            showAsActive={showAsActive}
          />
        ))}
      {customerSettings.customerTaskCreationWizard ? (
        <CustomerTaskCreationDisplay send={taskCreationSend} state={taskCreationState} />
      ) : null}

      <LocationCreateEditDialog
        customerLookup={customerLookup}
        location={locationDialogOpenedFor || undefined}
        locationFavoritesEnabled={customerSettings.locationFavoritesEnabled}
        onCancel={handleLocationCreateEditDialogCancel}
        onOk={handleExtendedLocationCreateEditDialogOk}
        open={!!locationDialogOpenedFor}
      />
      <SpinnerDialog
        open={spinnerMoveDialogOpen}
        title={intl.formatMessage({
          defaultMessage: "Ændrer kunde på stedet",
        })}
      />

      <ResponsiveDialog
        onOk={setErrorMoveDialogOpenFalse}
        open={errorMoveDialogOpen}
        title={intl.formatMessage({defaultMessage: "Fejl"})}
      >
        <DialogContent>
          <FormattedMessage defaultMessage="Der opstod en fejl under flytningen, kontakt support" />
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
}
