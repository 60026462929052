import {Task, TaskPhoto, UserPhoto} from "@co-common-libs/resources";
import {getCustomerSettings} from "@co-frontend-libs/redux";
import {Grid} from "@material-ui/core";
import {PhotoAndPdfUpload} from "app-components";
import React from "react";
import {useSelector} from "react-redux";
import {OtherPhotosAndFiles} from "./other-photos-and-files";

interface PhotosTabProps {
  completed: boolean;
  onPhotoDisplay: (photo: TaskPhoto | UserPhoto) => void;
  onRequestFileDelete: (url: string) => void;
  onRequestPhotoDelete: (url: string) => void;
  task: Task;
  userIsOnlyMachineOperator: boolean;
  userIsOtherMachineOperator: boolean;
  validated: boolean;
}

export function PhotosTab(props: PhotosTabProps): React.JSX.Element {
  const {
    completed,
    onPhotoDisplay,
    onRequestFileDelete,
    onRequestPhotoDelete,
    task,
    userIsOnlyMachineOperator,
    userIsOtherMachineOperator,
    validated,
  } = props;

  const customerSettings = useSelector(getCustomerSettings);
  const editDisabled =
    userIsOtherMachineOperator ||
    (userIsOnlyMachineOperator &&
      completed &&
      !customerSettings.machineOperatorMayAddTaskPhotosAfterCompletion) ||
    (validated && !customerSettings.alwaysAllowManagerTaskPhotosEdit);

  return (
    <Grid container spacing={2}>
      <Grid item style={{paddingLeft: "20px"}} xs={12}>
        <PhotoAndPdfUpload
          editDisabled={editDisabled}
          onPhotoDisplay={onPhotoDisplay}
          onRequestFileDelete={onRequestFileDelete}
          onRequestPhotoDelete={onRequestPhotoDelete}
          task={task}
        />
      </Grid>
      {(task.order && customerSettings.taskShowRelatedPhotos) ||
      customerSettings.taskShowRelatedFiles ? (
        <Grid item xs={12}>
          <OtherPhotosAndFiles currentTask={task} onPhotoDisplay={onPhotoDisplay} />
        </Grid>
      ) : null}
    </Grid>
  );
}
