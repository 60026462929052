import {CustomerUrl, ProjectUrl} from "@co-common-libs/resources";
import {assign, createMachine, StateMachine} from "@xstate/fsm";

interface ProjectCustomerSelectionContext {
  customer: CustomerUrl | null;
  project: ProjectUrl | null;
}

export type ProjectCustomerSelectionEvent =
  | {
      customer: CustomerUrl;
      project: ProjectUrl;
      type: "PROJECT_SELECTED";
    }
  | {
      customer: CustomerUrl;
      type: "CUSTOMER_SELECTED";
    }
  | {
      customer: CustomerUrl | null;
      projectsEnabled: boolean;
      type: "START";
    }
  | {type: "ADD"}
  | {type: "CANCEL"}
  | {type: "CREATED"; url: ProjectUrl}
  | {type: "NONE"};

type ProjectCustomerSelectionTypeState =
  | {
      context: ProjectCustomerSelectionContext;
      value: "initial";
    }
  | {
      context: ProjectCustomerSelectionContext & {
        customer: CustomerUrl;
        project: null;
      };
      value: "createNewProject";
    }
  | {
      context: ProjectCustomerSelectionContext & {
        customer: CustomerUrl;
        project: null;
      };
      value: "selectCustomerProject";
    }
  | {
      context: ProjectCustomerSelectionContext & {
        customer: null;
        project: null;
      };
      value: "selectCustomer";
    }
  | {
      context: ProjectCustomerSelectionContext & {
        customer: null;
        project: null;
      };
      value: "selectCustomerForNewProject";
    }
  | {
      context: ProjectCustomerSelectionContext & {
        customer: null;
        project: null;
      };
      value: "selectProject";
    };

export type ProjectCustomerSelectionState = StateMachine.State<
  ProjectCustomerSelectionContext,
  ProjectCustomerSelectionEvent,
  ProjectCustomerSelectionTypeState
>;

export const projectCustomerSelectionStateMachine = createMachine<
  ProjectCustomerSelectionContext,
  ProjectCustomerSelectionEvent,
  ProjectCustomerSelectionTypeState
>({
  context: {
    customer: null,
    project: null,
  },
  id: "projectCustomerSelection",
  initial: "initial",
  states: {
    createNewProject: {
      on: {
        CANCEL: {
          actions: "signalCancelled",
          target: "initial",
        },
        CREATED: {
          actions: [
            assign({
              project: (_context, event) => event.url,
            }),
            "signalDone",
          ],
          target: "initial",
        },
      },
    },
    initial: {
      on: {
        START: [
          {
            actions: assign({
              customer: (_context, event) => event.customer,
            }),
            cond: (_context, event) => event.projectsEnabled && !!event.customer,
            target: "selectCustomerProject",
          },
          {
            actions: assign({
              customer: (_context, _event) => null,
            }),
            cond: (_context, event) => event.projectsEnabled && !event.customer,
            target: "selectProject",
          },
          {
            actions: assign({
              customer: (_context, _event) => null,
            }),
            cond: (_context, event) => !event.projectsEnabled && !event.customer,
            target: "selectCustomer",
          },
          {
            actions: [
              assign({
                customer: (_context, event) => event.customer,
                project: (_context, _event) => null,
              }),
              "signalDone",
            ],
            cond: (_context, event) => !event.projectsEnabled && !!event.customer,
            target: "initial",
          },
        ],
      },
    },
    selectCustomer: {
      on: {
        CANCEL: {
          actions: "signalCancelled",
          target: "initial",
        },
        CUSTOMER_SELECTED: {
          actions: [
            assign({
              customer: (_context, event) => event.customer,
              project: (_context, _event) => null,
            }),
            "signalDone",
          ],
          target: "initial",
        },
      },
    },
    selectCustomerForNewProject: {
      on: {
        CANCEL: {
          actions: "signalCancelled",
          target: "initial",
        },
        CUSTOMER_SELECTED: {
          actions: assign({
            customer: (_context, event) => event.customer,
          }),
          target: "createNewProject",
        },
      },
    },
    selectCustomerProject: {
      on: {
        ADD: "createNewProject",
        CANCEL: {
          actions: "signalCancelled",
          target: "initial",
        },
        NONE: {
          actions: [
            assign({
              project: (_context, _event) => null,
            }),
            "signalDone",
          ],
          target: "initial",
        },
        PROJECT_SELECTED: {
          actions: [
            assign({
              project: (_context, event) => event.project,
            }),
            "signalDone",
          ],
          target: "initial",
        },
      },
    },
    selectProject: {
      on: {
        ADD: "selectCustomerForNewProject",
        CANCEL: {
          actions: "signalCancelled",
          target: "initial",
        },
        NONE: {
          actions: assign({
            project: (_context, _event) => null,
          }),
          target: "selectCustomer",
        },
        PROJECT_SELECTED: {
          actions: [
            assign({
              customer: (_context, event) => event.customer,
              project: (_context, event) => event.project,
            }),
            "signalDone",
          ],
          target: "initial",
        },
      },
    },
  },
});
