import {ImportPreviewProductGroup} from "@co-common-libs/resources-utils";
import {ImportProductGroupsPreviewDialog} from "@co-frontend-libs/components";
import {jsonFetch, translateNetworkError} from "@co-frontend-libs/utils";
import {useAsyncAbortable} from "@react-hookz/web";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useEffect} from "react";
import {useIntl} from "react-intl";

interface ImportProductGroupsDialogProps {
  filter?: ReadonlySet<string>;
  importType: "PRODUCT_GROUP" | "WORK_TYPE";
  onCancel(): void;
  onOk(productGroups: ImportPreviewProductGroup[]): void;
  open: boolean;
}

export const ImportProductGroupsDialog = React.memo(function ImportProductGroupsDialog(
  props: ImportProductGroupsDialogProps,
): React.JSX.Element {
  const {filter, open} = props;

  const {baseURL} = globalConfig.resources;
  const [state, actions] = useAsyncAbortable<readonly ImportPreviewProductGroup[] | null>(
    (signal) =>
      jsonFetch(`${baseURL}economic/product_groups/`).then(({data}) => {
        if (signal.aborted) {
          return [];
        } else {
          return filter
            ? (data as ImportPreviewProductGroup[]).filter((entry) => !filter.has(entry.remoteUrl))
            : data;
        }
      }),
    null,
  );

  useEffect(() => {
    if (open) {
      actions.execute();
    } else {
      actions.reset();
    }
  }, [actions, open]);

  const onOk = useCallback(
    (identifiers: ReadonlySet<string>) => {
      props.onOk(
        (state.result || []).filter((productGroup) => identifiers.has(productGroup.identifier)),
      );
    },
    [state.result, props],
  );

  const intl = useIntl();

  return (
    <ImportProductGroupsPreviewDialog
      {...props}
      error={state.status === "error" ? translateNetworkError(state.error, intl) : undefined}
      onOk={onOk}
      previewProductGroups={state.result}
    />
  );
});
