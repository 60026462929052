import {
  CultureUrl,
  CustomerUrl,
  LocationUrl,
  MachineUrl,
  OrderUrl,
  ProjectUrl,
  urlToId,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {jsonFetch} from "@co-frontend-libs/utils";
import {globalConfig} from "frontend-global-config";
import {useEffect, useState} from "react";
import {LogTaskData, PhotoTaskData} from "./types";

interface AppendicesDataState {
  data: {
    readonly logTasks?: readonly LogTaskData[];
    readonly photoTasks?: readonly PhotoTaskData[];
  } | null;
  fetching: boolean;
}

interface AppendicesRequest {
  cultures: readonly CultureUrl[];
  customers: readonly CustomerUrl[];
  fromDate: string;
  location: LocationUrl;
  machines: readonly MachineUrl[];
  order: OrderUrl;
  project: ProjectUrl;
  referenceNumber: string;
  toDate: string;
  workTypes: readonly WorkTypeUrl[];
}

export const useAppendicesData = (params: Partial<AppendicesRequest>): AppendicesDataState => {
  const [state, setState] = useState<AppendicesDataState>({
    data: null,
    fetching: false,
  });

  useEffect((): void => {
    const {baseURL} = globalConfig.resources;
    const url = `${baseURL}appendices`;

    setState({
      data: null,
      fetching: true,
    });

    const {
      cultures,
      customers,
      fromDate,
      location,
      machines,
      order,
      project,
      referenceNumber,
      toDate,
      workTypes,
    } = params;

    jsonFetch(url, "POST", {
      cultures: cultures ? cultures.map(urlToId) : null,
      customers: customers ? customers.map(urlToId) : null,
      fromDate: fromDate || null,
      location: location || null,
      machines: machines ? machines.map(urlToId) : null,
      order: order ? urlToId(order) : null,
      project: project ? urlToId(project) : null,
      referenceNumber: referenceNumber || null,
      toDate: toDate || null,
      workTypes: workTypes ? workTypes.map(urlToId) : null,
    })
      .then((response) => {
        setState({data: response.data, fetching: false});
        return;
      })
      .catch(() => {
        setState({
          data: null,
          fetching: false,
        });
      });
  }, [params]);

  return state;
};
