import {
  createStyles,
  IconButton,
  InputAdornment,
  makeStyles,
  OutlinedTextFieldProps,
  TextField,
  TextFieldProps,
  Theme,
} from "@material-ui/core";
import {MuiPickersContext} from "@material-ui/pickers";
import bowser from "bowser";
import {format, Locale, parse} from "date-fns";
import ClockIcon from "mdi-react/ClockIcon";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {TimeDialog, TimeDialogProps} from "./time-dialog";
import {useIsLandscapeOrientation} from "./use-is-landscape-orientation";

const timeFormats = ["HHmm", "HH:mm", "HH.mm", "HH,mm", "HH", "p"];

const threeDigits = /^\d{3}$/;

function parseTime(timeString: string, locale?: Locale): Date | undefined {
  const localeOption = locale ? {locale} : undefined;
  // special case; date-fns.parse may interpret three digits as
  // HHm even when the specified format is Hmm...
  if (threeDigits.test(timeString)) {
    return parseTime(`0${timeString}`, locale);
  }
  const now = new Date();
  for (let i = 0; i < timeFormats.length; i += 1) {
    const timeFormat = timeFormats[i];
    const result = parse(timeString, timeFormat, now, localeOption);
    if (!isNaN(result.valueOf())) {
      return result;
    }
  }
  return undefined;
}

function parseISOTime(timeString: string): Date {
  const withSeconds = parse(timeString, "HH:mm:ss", 0);
  if (!isNaN(withSeconds.valueOf())) {
    return withSeconds;
  }
  return parse(timeString, "HH:mm", 0);
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    adornedEnd: {
      paddingRight: 0,
    },
  }),
);

interface CommonTimeFieldProps {
  // autoOk does not currently work
  // autoOk?: boolean;
  native?: boolean;
  onChange: (value: string | null) => void;
  showButton?: boolean;
  value?: string | undefined;
}

// HACK: we've hardcoded variant="outlined" parameter to the TextField...
/*
type StandardTimeFieldProps = Omit<
  StandardTextFieldProps,
  "onChange" | "inputRef"
> &
  CommonTimeFieldProps;

type FilledTimeFieldProps = Omit<
  FilledTextFieldProps,
  "onChange" | "inputRef"
> &
  CommonTimeFieldProps;
*/
type OutlinedTimeFieldProps = CommonTimeFieldProps &
  Omit<OutlinedTextFieldProps, "inputRef" | "onChange" | "variant">;

export type TimeFieldProps =
  // | StandardTimeFieldProps
  // | FilledTimeFieldProps
  OutlinedTimeFieldProps;

export function TimeField(props: TimeFieldProps): React.JSX.Element {
  const {
    // autoOk,
    InputProps: InputPropsFromProps,
    native: nativeFromProps,
    onBlur: onBlurFromProps,
    onChange,
    onFocus: onFocusFromProps,
    showButton: showButtonFromProps,
    value: valueFromProps,
    ...others
  } = props;

  const classes = useStyles();

  // for iOS/Android, we use <input type="time"> to show native picker;
  // prop override is for testing
  const native = nativeFromProps ?? (bowser.ios || bowser.android || false);
  const locale = useContext(MuiPickersContext)?.locale as Locale | undefined;

  const isLandscapeOrientation = useIsLandscapeOrientation();

  const localeOption = useMemo(() => (locale ? {locale} : undefined), [locale]);

  const dateFromProps = useMemo(
    () => (valueFromProps ? parseISOTime(valueFromProps) : undefined),
    [valueFromProps],
  );
  const textFromProps = dateFromProps
    ? native
      ? valueFromProps
      : format(dateFromProps, "p", localeOption)
    : "";

  // value in local state while focused/while typing;
  // but "synced out" if resulting value changed
  const [text, setText] = useState(textFromProps);
  const onTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const newText = event.target.value;
      setText(newText);
      if (native) {
        const newValue =
          event.target.validity && event.target.validity.valid ? newText || null : null;
        if (newValue !== (valueFromProps ?? null)) {
          onChange(newValue);
        }
      } else {
        const date = parseTime(newText, locale);
        const newValue = date ? format(date, "HH:mm:00") : null;
        if (newValue !== (valueFromProps ?? null)) {
          onChange(newValue);
        }
      }
    },
    [locale, native, onChange, valueFromProps],
  );

  const ref = useRef<HTMLInputElement>();
  // NTS: "clear" hack for iOS still required:
  // Where Android clears to "", iOS clears to defaultValue,
  // which React unhelpfully sets to the same as value...
  useEffect(() => {
    if (bowser.ios) {
      return () => {
        // intentionally accessing
        if (ref.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          ref.current.defaultValue = "";
        }
      };
    } else {
      return undefined;
    }
  });

  // track focus to display local state when focused;
  // copy current outer value into local state on gaining focus
  const [focused, setFocused] = useState(props.autoFocus || false);
  const onFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>): void => {
      setFocused(true);
      setText(textFromProps);
      if (onFocusFromProps) {
        onFocusFromProps(event);
      }
    },
    [onFocusFromProps, textFromProps],
  );
  const onBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>): void => {
      setFocused(false);
      if (onBlurFromProps) {
        onBlurFromProps(event);
      }
    },
    [onBlurFromProps],
  );

  // dialog open state
  const [dialogOpen, setDialogOpen] = useState(false);
  const openDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);
  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);
  const selectedInDialog = useCallback(
    (date: Date | null) => {
      setDialogOpen(false);
      const newValue = date ? format(date, "HH:mm:00") : null;
      const newText = date ? (native ? (newValue as string) : format(date, "p", localeOption)) : "";
      setText(newText);
      if (newValue !== (valueFromProps ?? null)) {
        onChange(newValue);
      }
    },
    [localeOption, native, onChange, valueFromProps],
  );

  // use native timepicker for Android in portrait mode
  // but MUI TimeDialog in landscape mode to mitigate UI issue
  // see: https://customoffice.monday.com/boards/2715683423/pulses/5643812358

  // eslint-disable-next-line no-restricted-globals
  const enoughHeightForMUI = screen.availHeight >= 400;

  // eslint-disable-next-line no-restricted-globals
  const notEnoughWidthForNative = screen.availWidth <= 800;

  // TODO(Q2 2024 or later): check if native timepicker works in landscape mode on Android despite small width
  const useMUIOnNative =
    bowser.android && isLandscapeOrientation && enoughHeightForMUI && notEnoughWidthForNative;

  const showButton = showButtonFromProps ?? !(native && bowser.android);

  const InputProps = useMemo(() => {
    const InputPropsForButton = showButton
      ? {
          classes: {adornedEnd: classes.adornedEnd},
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                color="inherit"
                disabled={props.disabled || false}
                onClick={openDialog}
                tabIndex={-1}
              >
                <ClockIcon />
              </IconButton>
            </InputAdornment>
          ),
        }
      : undefined;
    if (InputPropsFromProps && InputPropsForButton) {
      return {...InputPropsForButton, ...InputPropsFromProps};
    } else {
      return InputPropsForButton || InputPropsFromProps;
    }
  }, [InputPropsFromProps, openDialog, props.disabled, showButton, classes.adornedEnd]);

  const textFieldOptionalProps: Partial<TextFieldProps> = {};
  if (InputProps) {
    textFieldOptionalProps.InputProps = InputProps;
  }

  const timeDialogOptionalProps: Partial<TimeDialogProps> = {};
  if (dateFromProps) {
    timeDialogOptionalProps.value = dateFromProps;
  }

  return (
    <>
      <TextField
        {...textFieldOptionalProps}
        style={{minWidth: 200}}
        type={native ? (useMUIOnNative ? "button" : "time") : "text"}
        {...others}
        inputRef={ref}
        onBlur={onBlur}
        onChange={onTextChange}
        onClick={useMUIOnNative ? openDialog : undefined}
        onFocus={onFocus}
        required
        value={focused ? text : textFromProps}
        variant="outlined"
      />
      <TimeDialog
        onCancel={closeDialog}
        onOk={selectedInDialog}
        // autoOk={autoOk}
        open={dialogOpen}
        {...timeDialogOptionalProps}
      />
    </>
  );
}
